import React from "react";
import { Box, Typography } from "@mui/material";
import {
	DEPLOYMENT_STATUS,
	WSREP_MEMBER_STATE
} from "components/management/cluster/types";
import { DB_STATE, NODE_STATE } from "components/management/node/types";
import { styles } from "./styles";
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';

interface LocalState {}

interface LocalProps {
	nodeState: NODE_STATE;
}

type Props = LocalProps & WithStyles<typeof styles>;

class NodeStateComponent extends React.PureComponent<Props, LocalState> {
	render() {
		const { nodeState, classes } = this.props;

		const renderNodeState = (nodeState: NODE_STATE) => {
			switch (nodeState) {
				case WSREP_MEMBER_STATE.UNDEFINED:
					return <Typography>UNDEFINED</Typography>;
				case WSREP_MEMBER_STATE.JOINER:
					return <Typography className={classes.stateOk}>JOINER</Typography>;
				case WSREP_MEMBER_STATE.DONOR:
					return <Typography className={classes.stateOk}>DONOR</Typography>;
				case WSREP_MEMBER_STATE.JOINED:
					return <Typography className={classes.stateOk}>JOINED</Typography>;
				case WSREP_MEMBER_STATE.SYNCED:
					return <Typography className={classes.stateOk}>SYNCED</Typography>;
				case WSREP_MEMBER_STATE.ERROR:
					return <Typography className={classes.stateError}>ERROR</Typography>;
				case WSREP_MEMBER_STATE.MAX:
					return <Typography>MAX</Typography>;
				case DB_STATE.UNKNOWN:
					return "";
				case DB_STATE.DOWN:
					return (
						<Typography component="div">
							<Box textAlign="right">NOT RUNNING</Box>
						</Typography>
					);
				case DEPLOYMENT_STATUS.FAILURE:
					return (
						<Typography className={classes.stateError} component="div">
							<Box textAlign="right">DEPLOYMENT ERROR</Box>
						</Typography>
					);

				default:
					return <Typography>UNKNOWN</Typography>;
			}
		};

		return <>{renderNodeState(nodeState)}</>;
	}
}

export default withStyles(styles)(NodeStateComponent);
