import {
	METRICS_ACTION,
	MetricsAction,
	MetricsStoreState
} from "modules/metricsStore/storeTypes";
import MetricsUtils from "modules/metricsStore/utils";

const defaultState: MetricsStoreState = {
	isMonitorEnabled: false,
	wsrepLastCommittedMetrics: {},
	wsrepLocalStateMetrics: {},
	joinedNodes: {}
};

export function metricsReducer(
	state: MetricsStoreState = defaultState,
	action: MetricsAction
): MetricsStoreState {
	switch (action.type) {
		case METRICS_ACTION.START_MONITOR:
			return {
				...state,
				isMonitorEnabled: true
			};

		case METRICS_ACTION.STOP_MONITOR:
			return {
				...state,
				isMonitorEnabled: false
			};

		case METRICS_ACTION.FETCH_WSREP_LOCAL_STATE_METRICS_SUCCESS:
			return {
				...state,
				wsrepLocalStateMetrics: MetricsUtils.toRecord(action.payload.metrics),
				joinedNodes: MetricsUtils.countJoinedNodes(action.payload.metrics)
			};

		case METRICS_ACTION.FETCH_WSREP_LAST_COMMITTED_METRICS_SUCCESS:
			return {
				...state,
				wsrepLastCommittedMetrics: {
					...state.wsrepLastCommittedMetrics,
					...action.payload.metrics
				}
			};

		default:
			return state;
	}
}
