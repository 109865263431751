import { Grid, Typography } from "@mui/material";
import React from "react";

interface LocalProps {
	message: string;
}

class ChartMessage extends React.PureComponent<LocalProps> {
	render() {
		const { message } = this.props;

		return (
			<Grid
				container
				alignContent="center"
				justifyContent="center"
				style={{
					position: "absolute",
					top: "0",
					left: "0",
					right: "0",
					bottom: "0",
					backgroundColor: "rgba(255, 255, 255, 0.4)",
					zIndex: 1
				}}
			>
				<Grid item>
					<Typography style={{ cursor: "default" }} color="primary">
						{message}
					</Typography>
				</Grid>
			</Grid>
		);
	}
}

export default ChartMessage;
