import {
	Delete,
	Error,
	ErrorOutline,
	HelpOutline,
	VerifiedUser,
	VpnKey
} from "@mui/icons-material";
import {
	Avatar,
	Button,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	Popover,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import { WithStyles, WithTheme } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { CLUSTER_TYPE } from "components/management/cluster/clusterCreateWizard/types";
import { CONNECTION_TEST_STATUS } from "components/sharedComponents/connectionTestDialog/types";
import { showSnackbar } from "components/sharedComponents/snackbar/actionCreators";
import { SnackbarActionPayload } from "components/sharedComponents/snackbar/types";
import { ContentCopy } from "mdi-material-ui";
import React, { ChangeEvent, Fragment } from "react";
import { connect } from "react-redux";
import * as sshpk from "sshpk";
import { styles } from "./styles";

interface LocalState {
	isDialogOpen: boolean;
	isPopoverOpen: boolean;
	popoverAnchorEl: Element | null;
	popoverContent: string;
	addNewKey: {
		value: string;
		isValid: boolean;
		message: string;
	};
	expandedKeys: string[];
}

interface LocalProps {
	authorizedKeys: string[];
	inheritedAuthorizedKeys?: string[];
	readOnly: boolean;
	clusterType: CLUSTER_TYPE;
	onRemove?: (publicKey: string, index: number) => void;
	onAdd?: (publicKey: string) => void;
	sshTestStatus?: CONNECTION_TEST_STATUS;
}

interface DispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
}

type Props = LocalProps & DispatchProps & WithStyles<typeof styles> & WithTheme;

class SSHKeysEditor extends React.PureComponent<Props, LocalState> {
	defaultState: LocalState = {
		isDialogOpen: false,
		isPopoverOpen: false,
		popoverAnchorEl: null,
		popoverContent: "",
		addNewKey: {
			value: "",
			isValid: false,
			message: ""
		},
		expandedKeys: []
	};

	constructor(props: Props) {
		super(props);

		this.state = this.defaultState;
	}

	openDialog = (): void => {
		const { authorizedKeys, inheritedAuthorizedKeys = [] } = this.props;

		let expandedKeys: string[] = [];

		let uniqueKeys = [...authorizedKeys, ...inheritedAuthorizedKeys].filter(
			(key: string, index: number, array: string[]) =>
				array.indexOf(key) === index
		);

		if (uniqueKeys.length === 1) {
			expandedKeys = uniqueKeys;
		}

		this.setState((state: LocalState) => ({
			...state,
			authorizedKeys: this.props.authorizedKeys,
			isDialogOpen: true,
			expandedKeys
		}));
	};

	closeDialog = (): void => {
		this.setState((state: LocalState) => ({ ...state, isDialogOpen: false }));
	};

	onPublicKeyChanged = (e: ChangeEvent<HTMLInputElement>): void => {
		e.persist();
		let isValid = false;
		let message = "";
		const value = e.target.value;

		if (value) {
			try {
				sshpk.parseKey(value, "auto");
				isValid = true;
				// message = `Valid ${key.type.toUpperCase()} ${
				// 	key.size
				// }bit key. Press ENTER or click "add" icon on the right to save the key.`;
			} catch (e) {
				// console.warn("Not valid public key");
				message = "Please enter valid SSH public key";
			}

			if (
				this.props.authorizedKeys.includes(value) ||
				(this.props.inheritedAuthorizedKeys &&
					this.props.inheritedAuthorizedKeys.includes(value))
			) {
				isValid = false;
				message = "Key already exists";
			}
		}

		if (isValid) {
			this.props.onAdd
				? this.props.onAdd(value)
				: console.warn("Trying to add key, but props.onAdd is not defined.");
			this.setState((state: LocalState) => ({
				...state,
				addNewKey: {
					...state.addNewKey,
					value: "",
					isValid: false,
					message: ""
				}
			}));
		} else {
			this.setState((state: LocalState) => ({
				...state,
				addNewKey: {
					...state.addNewKey,
					value,
					isValid,
					message: message
				}
			}));
		}
	};

	render() {
		const {
			classes,
			readOnly,
			theme,
			authorizedKeys,
			inheritedAuthorizedKeys,
			clusterType,
			sshTestStatus
		} = this.props;
		const {
			isDialogOpen,
			isPopoverOpen,
			popoverAnchorEl,
			popoverContent,
			addNewKey,
			expandedKeys
		} = this.state;

		let keysCount = authorizedKeys.length;
		let inheritedKeysCount = 0;

		if (inheritedAuthorizedKeys) {
			inheritedKeysCount = inheritedAuthorizedKeys.filter(
				(publicKey: string) => !authorizedKeys?.includes(publicKey)
			).length;
			keysCount += inheritedKeysCount;
		}

		const ListItemComponent = ListItem as any; // temporary workaround because of bug in TS/MUI: https://github.com/mui-org/material-ui/issues/14971

		let subtitleText = "";

		if (clusterType === CLUSTER_TYPE.MANAGED) {
			if (readOnly) {
				subtitleText =
					"Below is the list of keys that have root access to nodes.";
			} else {
				subtitleText =
					"If you want to have access to nodes, add your public key here. Keys will be added as authorized keys on nodes under root user (/root/.ssh/authorized_keys).";
			}
		} else if (
			clusterType === CLUSTER_TYPE.HYBRID ||
			clusterType === CLUSTER_TYPE.MONITORED
		) {
			if (readOnly) {
				subtitleText =
					"Below is the public key that must have access to the node.";
			} else {
				subtitleText =
					"Below is the public key that must have access to the node. Make sure you have added this key to the node's authorized keys file under root user (/root/.ssh/authorized_keys).";
			}
		}

		const renderKeyItem = (
			publicKey: string,
			index: number,
			inherited = false
		) => {
			const key = sshpk.parseKey(publicKey, "auto");

			return (
				<Fragment key={publicKey}>
					<ListItemComponent
						data-testid="pub-key"
						button={true}
						onClick={() => {
							const expanded = expandedKeys.includes(publicKey);

							if (expanded) {
								this.setState((state: LocalState) => ({
									expandedKeys: state.expandedKeys.filter(
										(key: string) => key !== publicKey
									)
								}));
							} else {
								this.setState((state: LocalState) => ({
									expandedKeys: [...state.expandedKeys, publicKey]
								}));
							}
						}}
					>
						<ListItemAvatar>
							<Avatar
								style={{
									backgroundColor: theme.palette.primary.main
								}}
							>
								<VpnKey />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={key.comment}
							secondary={`${key.type.toUpperCase()} ${key.size} ${
								inherited ? "(Inherited from cluster)" : ""
							}`}
						/>
						<ListItemSecondaryAction>
							<Tooltip title={"Copy key"}>
								<IconButton
									onClick={() => {
										if (navigator.clipboard) {
											navigator.clipboard.writeText(publicKey).then(
												() => {
													this.props.showSnackbar({
														msg: "Public key copied to clipboard"
													});
												},
												(err: Error) => {
													console.error("Password copy error:", err);
													this.props.showSnackbar({
														msg: "Couldn't copy public key to clipboard"
													});
												}
											);
										} else {
											this.props.showSnackbar({
												msg: "Couldn't copy public key because the application is not served over HTTPS"
											});
										}
									}}
									edge="end"
									aria-label="Copy"
									size="large"
								>
									<ContentCopy />
								</IconButton>
							</Tooltip>
							{!inherited && !readOnly && (
								<Tooltip title={"Remove key"}>
									<IconButton
										data-testid="remove-key-button"
										onClick={(): void => {
											this.props.onRemove
												? this.props.onRemove(publicKey, index)
												: console.warn(
														"Trying to remove key, but props.onRemove is not defined"
												  );
										}}
										edge="end"
										aria-label="Delete"
										size="large"
									>
										<Delete />
									</IconButton>
								</Tooltip>
							)}
						</ListItemSecondaryAction>
					</ListItemComponent>
					<Collapse
						data-testid="pub-key-value"
						in={expandedKeys.includes(publicKey)}
					>
						<Typography className={classes.publicKey}>{publicKey}</Typography>
					</Collapse>
				</Fragment>
			);
		};

		const renderDialog = () => (
			<>
				{theme.breakpoints.up("sm") ? (
					<Dialog
						open={isDialogOpen}
						onClose={(event: Event, reason: string) => {
							if (reason === "escapeKeyDown") {
								this.closeDialog();
							}
						}}
						fullWidth={true}
						maxWidth={"sm"}
						aria-labelledby="form-dialog-title"
					>
						{dialogContentRender()}
					</Dialog>
				) : (
					<Dialog
						open={isDialogOpen}
						onClose={(event: Event, reason: string) => {
							if (reason === "escapeKeyDown") {
								this.closeDialog();
							}
						}}
						fullWidth={true}
						fullScreen={true}
						aria-labelledby="form-dialog-title"
					>
						{dialogContentRender()}
					</Dialog>
				)}
			</>
		);

		const dialogContentRender = () => (
			<>
				<DialogTitle id="form-dialog-title">SSH keys</DialogTitle>
				<DialogContent>
					<Grid container>
						<DialogContentText variant={"subtitle2"}>
							{subtitleText}
						</DialogContentText>
						<List className={classes.root}>
							{inheritedAuthorizedKeys &&
								inheritedAuthorizedKeys.map((publicKey: string, index) => {
									return renderKeyItem(publicKey, index, true);
								})}
							{authorizedKeys
								.filter(
									(publicKey: string) =>
										!inheritedAuthorizedKeys?.includes(publicKey)
								)
								.map((publicKey: string, index) => {
									return renderKeyItem(publicKey, index);
								})}
						</List>
						{!readOnly && clusterType === CLUSTER_TYPE.MANAGED ? (
							<TextField
								autoFocus
								error={addNewKey.message !== "" && !addNewKey.isValid}
								helperText={addNewKey.message}
								multiline={true}
								margin="dense"
								id="publicKey"
								label="Paste SSH public key"
								type="text"
								fullWidth
								variant="outlined"
								value={addNewKey.value}
								onChange={this.onPublicKeyChanged}
								inputProps={{
									"data-testid": "pub-key-text-field",
									"data-cy": "authorized-keys-text-field",
									style: { fontFamily: "monospace" },
									spellCheck: false,
									autoComplete: "off",
									autoCorrect: "off",
									autoCapitalize: "off"
								}}
							/>
						) : (
							""
						)}

						<Popover
							open={isPopoverOpen}
							anchorEl={popoverAnchorEl}
							onClose={() => {
								console.log("onClose");
								this.setState(
									(state: LocalState): LocalState => ({
										...state,
										isPopoverOpen: false,
										popoverAnchorEl: null
									})
								);
							}}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center"
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "center"
							}}
						>
							<Typography style={{ padding: theme.spacing(2) }}>
								{popoverContent}
							</Typography>
						</Popover>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						data-cy="authorized-keys-editor-close-button"
						onClick={(): void => {
							this.closeDialog();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</>
		);

		const renderStatusIcon = (sshTestStatus: CONNECTION_TEST_STATUS) => {
			switch (sshTestStatus) {
				case CONNECTION_TEST_STATUS.SUCCESS:
					return (
						<Tooltip title={"Galera Manager has SSH access"}>
							<ListItemSecondaryAction>
								<VerifiedUser style={{ color: theme.palette.success.main }} />
							</ListItemSecondaryAction>
						</Tooltip>
					);
				case CONNECTION_TEST_STATUS.ERROR:
					return (
						<Tooltip
							title={
								"Galera Manager does not have SSH access. Check if public key is added on host."
							}
						>
							<ListItemSecondaryAction>
								<ErrorOutline color="error" />
							</ListItemSecondaryAction>
						</Tooltip>
					);
				default:
					return (
						<Tooltip
							title={
								"Please test access to make sure public key is added on node."
							}
						>
							<ListItemSecondaryAction>
								<HelpOutline color="primary" />
							</ListItemSecondaryAction>
						</Tooltip>
					);
			}
		};

		return (
			<>
				<List className={classes.root}>
					<ListItemComponent button={true} onClick={this.openDialog}>
						<ListItemAvatar>
							<Avatar
								style={
									keysCount > 0
										? { backgroundColor: theme.palette.primary.main }
										: {}
								}
							>
								<VpnKey />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							data-testid="ssh-keys-main-button"
							data-cy="authorized-keys"
							primary="SSH keys"
							secondary={
								keysCount > 0
									? `${keysCount} key/s ${
											inheritedKeysCount > 0
												? `(${inheritedKeysCount} inherited)`
												: ""
									  }`
									: "No keys"
							}
						/>
						{clusterType !== CLUSTER_TYPE.MANAGED && sshTestStatus
							? renderStatusIcon(sshTestStatus)
							: ""}
					</ListItemComponent>
				</List>
				{renderDialog()}
			</>
		);
	}
}

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		showSnackbar: (snackbar: SnackbarActionPayload) => {
			dispatch(showSnackbar(snackbar));
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	connect(undefined, mapGlobalDispatchToProps)(SSHKeysEditor)
);
