import { Dashboard } from "components/monitoring/dashboard/types";
import DashboardUtils from "components/monitoring/dashboard/utils";
import {
	DASHBOARD_ACTION,
	DashboardAction,
	DashboardState
} from "modules/dashboard/types";

export const defaultState: DashboardState = {
	saveInProgress: false,
	dashboards: new Map<string, Dashboard>()
};

export function dashboardReducer(
	state: DashboardState = defaultState,
	action: DashboardAction
): DashboardState {
	switch (action.type) {
		case DASHBOARD_ACTION.GET_SUCCESS:
			return {
				...state,
				getErrorMsg: undefined,
				dashboards: DashboardUtils.arrayToMap(action.payload.dashboards)
			};

		case DASHBOARD_ACTION.GET_FAILED:
			return {
				...state,
				getErrorMsg: action.payload.errorMsg
			};

		case DASHBOARD_ACTION.SAVE:
			return {
				...state,
				saveInProgress: true,
				dashboards: new Map<string, Dashboard>(state.dashboards).set(
					action.payload.dashboard.name,
					action.payload.dashboard
				)
			};

		case DASHBOARD_ACTION.SAVE_SUCCESS:
			return {
				...state,
				saveErrorMsg: undefined,
				saveInProgress: false,
				dashboards: DashboardUtils.arrayToMap(action.payload.dashboards)
			};

		case DASHBOARD_ACTION.SAVE_FAILED:
			return {
				...state,
				saveErrorMsg: action.payload.errorMsg,
				saveInProgress: false
			};

		default:
			return state;
	}
}
