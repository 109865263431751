import { authReducer } from "modules/auth/reducer";
import { dashboardReducer } from "modules/dashboard/reducer";
import { combineReducers } from "redux";
import snackbarReducer from "components/sharedComponents/snackbar/reducer";
import { userListReducer } from "components/users/reducer";
import { clusterListReducer } from "components/management/cluster/reducer";
import { nodeListReducer } from "components/management/node/reducer";
import { hostListReducer } from "components/management/host/reducer";
import { jobMonitorReducer } from "modules/jobs/monitor/reducer";
import { clusterCreateWizardReducer } from "components/management/cluster/clusterCreateWizard/reducer";
import { nodeCreateWizardReducer } from "components/management/node/nodeDeploymentDialog/reducer";
import { preloaderReducer } from "modules/preloader/reducer";
import { userCreateWizardReducer } from "components/users/userCreateWizard/reducer";
import { metricsReducer } from "modules/metricsStore/reducer";
import { systemStatusReducer } from "modules/systemStatus/reducer";
import { versionReducer } from "store/version/reducer";

export const rootReducer = combineReducers({
	preloader: preloaderReducer,
	auth: authReducer,
	dashboard: dashboardReducer,
	snackbar: snackbarReducer,
	userList: userListReducer,
	clusterList: clusterListReducer,
	nodeList: nodeListReducer,
	hostList: hostListReducer,
	jobMonitor: jobMonitorReducer,
	clusterCreateWizard: clusterCreateWizardReducer,
	nodeCreateWizard: nodeCreateWizardReducer,
	userCreateWizard: userCreateWizardReducer,
	metrics: metricsReducer,
	systemStatus: systemStatusReducer,
	versionInfo: versionReducer
});

export type AppState = ReturnType<typeof rootReducer>;
