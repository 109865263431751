import {
	HOST_SYSTEM,
	HOST_TYPE,
	HostSharedConfig
} from "components/management/host/types";
import { Region } from "@aws-sdk/client-ec2";

export const DEFAULT_HOST_SHARED_CONFIG: Readonly<HostSharedConfig> = {
	type: HOST_TYPE.EC2,
	system: HOST_SYSTEM.UBUNTU_20,
	hostTypeSpecific: {
		region: "eu-central-1",
		instanceType: "",
		accessKeyID: "",
		secretAccessKey: ""
	},
	databaseSizeGiB: 60
};

// Contains only regions with opt-in-not-required.
// Got the list from https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/using-regions-availability-zones.html#concepts-regions
export const DEFAULT_AWS_REGIONS: Region[] = [
	{ RegionName: "ap-northeast-1", OptInStatus: "opt-in-not-required" },
	{ RegionName: "ap-northeast-2", OptInStatus: "opt-in-not-required" },
	{ RegionName: "ap-northeast-3", OptInStatus: "opt-in-not-required" },
	{ RegionName: "ap-south-1", OptInStatus: "opt-in-not-required" },
	{ RegionName: "ca-central-1", OptInStatus: "opt-in-not-required" },
	{ RegionName: "eu-central-1", OptInStatus: "opt-in-not-required" },
	{ RegionName: "eu-north-1", OptInStatus: "opt-in-not-required" },
	{ RegionName: "eu-west-1", OptInStatus: "opt-in-not-required" },
	{ RegionName: "eu-west-2", OptInStatus: "opt-in-not-required" },
	{ RegionName: "eu-west-3", OptInStatus: "opt-in-not-required" },
	{ RegionName: "sa-east-1", OptInStatus: "opt-in-not-required" },
	{ RegionName: "us-east-1", OptInStatus: "opt-in-not-required" },
	{ RegionName: "us-east-2", OptInStatus: "opt-in-not-required" },
	{ RegionName: "us-west-1", OptInStatus: "opt-in-not-required" },
	{ RegionName: "us-west-2", OptInStatus: "opt-in-not-required" }
];
