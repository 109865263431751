import { Grid, Typography } from "@mui/material";
import React from "react";
import { LogLine } from "components/sharedComponents/logViewer/types";
import { styles } from "./styles";
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import moment from "moment";

interface LocalProps {
	logLine: LogLine;
	showNodeName?: boolean;
}

type Props = LocalProps & WithStyles<typeof styles>;

class LogMessage extends React.Component<Props, any> {
	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<any>,
		snapshot?: any
	): void {
		console.log("componentDidUpdate");
	}

	shouldComponentUpdate(
		nextProps: Readonly<Props>,
		nextState: Readonly<any>,
		nextContext: any
	): boolean {
		return (
			this.props.logLine._logLineHash !== nextProps.logLine._logLineHash ||
			this.props.showNodeName !== nextProps.showNodeName
		);
	}

	render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const { logLine, showNodeName, classes } = this.props;

		return (
			<Grid data-cy="log-message" container direction="row">
				<Typography
					component="span"
					variant="body2"
					classes={{ body2: classes.logMessagesTypography }}
					className={
						(logLine.message?.includes("[ERROR]") && classes.errorLog) ||
						(logLine.message?.includes("[Warning]") && classes.warningLog) ||
						""
					}
				>
					{`${moment(logLine.time).format("MMM DD, YYYY HH:mm:ss")} | ${
						showNodeName ? logLine.node_name + " | " : ""
					}${logLine.fpath} | ${logLine.message}`}
				</Typography>
			</Grid>
		);
	}
}

export default withStyles(styles)(LogMessage);
