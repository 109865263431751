import { HOST_SYSTEM } from "components/management/host/types";
import { NODE_DB_ENGINE } from "components/management/node/types";
import Config from "modules/config/Config";

export default class SupportMatrix {
	/**
	 * Returns list of supported DBs on system
	 * @param {HOST_SYSTEM} system
	 * @returns {NODE_DB_ENGINE[]}
	 */
	static getSupportedDbEngines = (system: HOST_SYSTEM): NODE_DB_ENGINE[] => {
		const support_matrix = Config.getInstance().support_matrix;

		if (!support_matrix) {
			return [NODE_DB_ENGINE.MARIADB_10_3];
		} else {
			return support_matrix[system];
		}
	};

	/**
	 * Returns list of supported systems for db
	 * @param {NODE_DB_ENGINE} dbEngine
	 * @returns {HOST_SYSTEM[]}
	 */
	static getSupportedHostSystems = (
		dbEngine: NODE_DB_ENGINE
	): HOST_SYSTEM[] => {
		const support_matrix = Config.getInstance().support_matrix;

		// console.log("supportMatrix", support_matrix);

		if (!support_matrix) {
			return [HOST_SYSTEM.CENTOS_7];
		} else {
			return support_matrix[dbEngine];
		}
	};

	/**
	 * Checks if system is supported by Galera Manager
	 * @param {string} hostSystem
	 * @returns {boolean}
	 */
	static isSystemSupported(hostSystem: string): boolean {
		return !!Config.getInstance().support_matrix[hostSystem];
	}

	/**
	 * Checks if db engine is supported by Galera Manager
	 * @param {string} dbEngine
	 * @returns {boolean}
	 */
	static isDbEngineSupported(dbEngine: string): boolean {
		return !!Config.getInstance().support_matrix[dbEngine];
	}

	/**
	 * Checks if system is supported by db engine
	 * @param {string} hostSystem
	 * @param {string} dbEngine
	 * @returns {boolean}
	 */
	static isSystemSupportedByDb(hostSystem: string, dbEngine: string): boolean {
		return !!Config.getInstance().support_matrix[dbEngine]?.includes(
			hostSystem
		);
	}

	/**
	 * Checks if db is supported on system
	 * @param {string} dbEngine
	 * @param {string} hostSystem
	 * @returns {boolean}
	 */
	static isDbSupportedOnSystem(dbEngine: string, hostSystem: string): boolean {
		return !!Config.getInstance().support_matrix[hostSystem]?.includes(
			dbEngine
		);
	}
}
