import { AppState } from "AppState";
import { Dashboard } from "components/monitoring/dashboard/types";
import DashboardUtils from "components/monitoring/dashboard/utils";

export const dashboardsSelector = (state: AppState): Map<string, Dashboard> => (state.dashboard.dashboards)

export const dashboardSelector = (
	state: AppState,
	dashboardName: string
): Dashboard => {

	const dashboards = state.dashboard.dashboards;
	const dashboard = dashboards.get(dashboardName);

	if (dashboard) {
		return dashboard;
	} else {
		return DashboardUtils.getDefaultConfiguration(dashboardName);
	}
};

export const dashboardSaveInProgressSelector = (state: AppState): boolean =>
	state.dashboard.saveInProgress;

export const dashboardSaveErrorMsgSelector = (
	state: AppState
): string | undefined => state.dashboard.saveErrorMsg;
