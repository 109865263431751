// redux
// todo identifications are now numbers (clusterID, nodeID)
import {
	WsrepLastCommittedMetric,
	WsrepLocalStateMetric
} from "modules/metricsStore/types";

export interface MetricsStoreState {
	isMonitorEnabled: boolean;
	wsrepLocalStateMetrics: Record<string, WsrepLocalStateMetric>;
	wsrepLastCommittedMetrics: Record<string, WsrepLastCommittedMetric>;
	joinedNodes: Record<string, number>;
}

export enum METRICS_ACTION {
	START_MONITOR = "METRICS_ACTION/START_MONITOR",
	STOP_MONITOR = "METRICS_ACTION/STOP_MONITOR",
	FETCH_WSREP_LOCAL_STATE_METRICS = "METRICS_ACTION/FETCH_WSREP_LOCAL_STATE_METRICS",
	FETCH_WSREP_LOCAL_STATE_METRICS_SUCCESS = "METRICS_ACTION/FETCH_WSREP_LOCAL_STATE_METRICS_SUCCESS",
	FETCH_WSREP_LAST_COMMITTED_METRICS = "METRICS_ACTION/FETCH_WSREP_LAST_COMMITTED_METRICS",
	FETCH_WSREP_LAST_COMMITTED_METRICS_SUCCESS = "METRICS/FETCH_WSREP_LAST_COMMITTED_METRICS_SUCCESS"
}

export interface StartMonitorMetricsAction {
	type: METRICS_ACTION.START_MONITOR;
}

export interface StopMonitorMetricsAction {
	type: METRICS_ACTION.STOP_MONITOR;
}

export interface FetchWsrepLocalStateMetricsAction {
	type: METRICS_ACTION.FETCH_WSREP_LOCAL_STATE_METRICS;
}

export interface FetchWsrepLocalStateMetricsSuccessAction {
	type: METRICS_ACTION.FETCH_WSREP_LOCAL_STATE_METRICS_SUCCESS;
	payload: {
		metrics: WsrepLocalStateMetric[];
	};
}

export interface FetchLastCommittedMetricsAction {
	type: METRICS_ACTION.FETCH_WSREP_LAST_COMMITTED_METRICS;
}

export interface FetchLastCommittedMetricsSuccessAction {
	type: METRICS_ACTION.FETCH_WSREP_LAST_COMMITTED_METRICS_SUCCESS;
	payload: {
		metrics: Record<string, WsrepLastCommittedMetric>;
	};
}

export type MetricsAction =
	| StartMonitorMetricsAction
	| StopMonitorMetricsAction
	| FetchLastCommittedMetricsAction
	| FetchLastCommittedMetricsSuccessAction
	| FetchWsrepLocalStateMetricsAction
	| FetchWsrepLocalStateMetricsSuccessAction;
