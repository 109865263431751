import {
	CancelOutlined,
	Close,
	Done,
	ErrorOutline,
	PlayCircleOutline
} from "@mui/icons-material";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	IconButton,
	ListItemText,
	Switch,
	Tooltip,
	Typography
} from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import {
	DEPLOYMENT_STEP_STATUS,
	DEPLOYMENT_STEP_TYPE,
	DeploymentJob,
	DeploymentStep
} from "components/management/node/nodeDeploymentDialog/types";
import DeploymentLogMessage from "components/sharedComponents/logViewer/DeploymentLogMessage";
import { Adjust } from "mdi-material-ui";
import WSService, { HostLog } from "modules/webSocketHandler/WSService";
import React from "react";
import { bufferTime } from "rxjs/operators";
import { styles } from "./styles";

interface LocalState {
	logs: HostLog[];
	isSubscribedToLogs: boolean;
	lastLogMsg: string;
	showLog: boolean;
	autoScrollToBottom: boolean;
}

interface LocalProps {
	deploymentJob: DeploymentJob;
}

type Props = LocalProps & WithStyles<typeof styles>;

class NodeDeploymentMonitorComponent extends React.Component<
	Props,
	LocalState
> {
	// element placed at the bottom of the dialog (after log messages)
	// used for auto scrolling to bottom
	bottomElement?: HTMLDivElement = undefined;

	constructor(props: Props) {
		super(props);

		this.state = {
			showLog: false,
			isSubscribedToLogs: false,
			lastLogMsg: "Deployment started...",
			logs: [],
			autoScrollToBottom: true
		};
	}

	subscribeToLogs(): void {
		const { cluster, host } = this.props.deploymentJob;

		// console.log("subscribeToLogs", cluster, host, node);
		// subscribe to web socket messages containing deployment logs
		const $log = WSService.getInstance().subscribeToLogs(
			`cluster:${cluster.id}:host:${host.id}`
		);

		this.setState({ isSubscribedToLogs: true });

		// pipe to show last log msg
		$log.pipe(bufferTime(200)).subscribe({
			next: (newLogs: HostLog[]) => {
				if (newLogs.length > 0) {
					this.setState({ lastLogMsg: newLogs.splice(-1)[0].msg });
				}
			}
		});

		// pipe to fill full deployment log
		$log.pipe(bufferTime(500)).subscribe({
			next: (newLogs: HostLog[]) => {
				if (newLogs.length > 0) {
					this.setState((state: LocalState) => ({
						logs: [...state.logs, ...newLogs]
					}));
				}
			}
		});
	}

	componentWillUnmount(): void {
		WSService.getInstance().unsubscribeFromLogs(this.props.deploymentJob.host);
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>,
		snapshot?: any
	): void {
		// console.log(
		// 	"componentDidUpdate",
		// 	this.state.isSubscribedToLogs,
		// 	this.props.deploymentJob.host.id
		// );
		// when you receive host ID for the first time, subscribe to logs
		if (!this.state.isSubscribedToLogs && this.props.deploymentJob.host.id) {
			this.subscribeToLogs();
		}

		this.scrollToBottom();
	}

	scrollToBottom() {
		if (this.state.autoScrollToBottom) this.bottomElement?.scrollIntoView();
	}

	render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const { deploymentJob, classes } = this.props;
		const { lastLogMsg, showLog, logs, autoScrollToBottom } = this.state;

		function getTooltipText(
			stepType: DEPLOYMENT_STEP_TYPE,
			stepStatus: DEPLOYMENT_STEP_STATUS
		): string {
			let retText;

			switch (stepType) {
				case DEPLOYMENT_STEP_TYPE.HOST_DEPLOY:
					retText = "Host deployment: ";
					break;
				case DEPLOYMENT_STEP_TYPE.NODE_INSTALL:
					retText = "Node installation: ";
					break;
				case DEPLOYMENT_STEP_TYPE.NODE_START:
					retText = "Node start: ";
					break;
			}

			switch (stepStatus) {
				case DEPLOYMENT_STEP_STATUS.CANCELLED:
					retText += "Cancelled";
					break;
				case DEPLOYMENT_STEP_STATUS.PENDING:
					retText += "Pending";
					break;
				case DEPLOYMENT_STEP_STATUS.RUNNING:
					retText += "Running";
					break;
				case DEPLOYMENT_STEP_STATUS.DONE:
					retText += "Done";
					break;
				case DEPLOYMENT_STEP_STATUS.FAILED:
					retText += "Failed";
					break;
			}

			return retText;
		}

		function renderStepIndicators(deploymentJob: DeploymentJob) {
			return (
				<>
					{deploymentJob.steps
						.sort(
							(step1: DeploymentStep, step2: DeploymentStep) =>
								step1.type - step2.type
						)
						.map((step: DeploymentStep) => (
							<Grid item data-cy={`step-${step.type}`} key={step.type}>
								{step.status === DEPLOYMENT_STEP_STATUS.PENDING &&
									(deploymentJob.isDone ? (
										<Tooltip
											placement="top"
											title={getTooltipText(
												step.type,
												DEPLOYMENT_STEP_STATUS.CANCELLED
											)}
										>
											<CancelOutlined className={classes.jobCancelledIcon} />
										</Tooltip>
									) : (
										<Tooltip
											placement="top"
											title={getTooltipText(step.type, step.status)}
										>
											<Adjust className={classes.jobPendingIcon} />
										</Tooltip>
									))}
								{step.status === DEPLOYMENT_STEP_STATUS.RUNNING && (
									<Tooltip
										placement="top"
										title={getTooltipText(step.type, step.status)}
									>
										<PlayCircleOutline className={classes.jobRunningIcon} />
									</Tooltip>
								)}
								{step.status === DEPLOYMENT_STEP_STATUS.FAILED && (
									<Tooltip
										placement="top"
										title={getTooltipText(step.type, step.status)}
									>
										<ErrorOutline className={classes.jobErrorIcon} />
									</Tooltip>
								)}
								{step.status === DEPLOYMENT_STEP_STATUS.DONE && (
									<Tooltip
										placement="top"
										title={getTooltipText(step.type, step.status)}
									>
										<Done
											data-cy={`step-${step.type}-done`}
											className={classes.jobDoneIcon}
										/>
									</Tooltip>
								)}
							</Grid>
						))}
				</>
			);
		}

		return <>
            <Grid container item direction="row">
                <Grid item xs={9}>
                    <Tooltip title={"Click to show logs"}>
                        <ListItemText
                            onClick={() => {
                                this.setState({ showLog: true });
                            }}
                            data-cy={`${deploymentJob.node.name}-last-log-msg`}
                            primary={`${deploymentJob.node.name} (${deploymentJob.host.system}/${deploymentJob.node.dbEngine})`}
                            primaryTypographyProps={{
                                style: { cursor: "pointer" }
                            }}
                            secondary={
                                deploymentJob.steps[0].errorMessage ||
                                deploymentJob.steps[1].errorMessage ||
                                deploymentJob.steps[2]?.errorMessage ||
                                lastLogMsg
                            }
                            secondaryTypographyProps={{
                                noWrap: true,
                                classes: { body2: classes.lastLogText },
                                color:
                                    deploymentJob.steps[0].errorMessage ||
                                    deploymentJob.steps[1].errorMessage ||
                                    deploymentJob.steps[2]?.errorMessage
                                        ? "error"
                                        : "initial"
                            }}
                        />
                    </Tooltip>
                </Grid>

                <Grid
                    container
                    xs
                    item
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                    data-cy="deployment-steps"
                >
                    {renderStepIndicators(deploymentJob)}
                    {/*<Grid item className={classes.actions}>*/}
                    {/*	<Tooltip placement="top" title="Show logs">*/}
                    {/*		<IconButton*/}
                    {/*			onClick={() => {*/}
                    {/*				this.setState({ showLog: true });*/}
                    {/*			}}*/}
                    {/*		>*/}
                    {/*			<Console />*/}
                    {/*		</IconButton>*/}
                    {/*	</Tooltip>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>

            <Dialog
                onClose={(event: Event, reason: string) => {
                    if (reason === 'escapeKeyDown') {
                        this.setState({ showLog: false });
                    }
                }}
                maxWidth="xl"
                fullScreen={true}
                fullWidth={true}
                open={showLog}
            >
                <DialogTitle classes={{ root: classes.dialogTitle }}>
                    {`${deploymentJob.node.name} deployment logs`}
                    <FormControlLabel
                        control={
                            <Switch
                                checked={autoScrollToBottom}
                                onChange={() => {
                                    this.setState({
                                        autoScrollToBottom: !autoScrollToBottom
                                    });
                                }}
                                name="autoScrollSwitch"
                                color="primary"
                            />
                        }
                        labelPlacement="start"
                        label="Auto-scroll to bottom"
                    />
                    <Grid container>
                        <Typography variant="subtitle2">Progress:</Typography>
                        {renderStepIndicators(deploymentJob)}
                    </Grid>
                    <IconButton
                        aria-label="close"
                        data-cy="live-logs-dialog-close-button"
                        className={classes.closeButton}
                        onClick={() => {
                            this.setState({ showLog: false });
                        }}
                        size="large">
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.logContent}>
                    {logs.map((log: HostLog, index: number) => (
                        <DeploymentLogMessage key={index} logLine={log} />
                    ))}
                    <div
                        id="bottom-element"
                        ref={(el: HTMLDivElement) => {
                            this.bottomElement = el;
                            this.scrollToBottom();
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>;
	}
}

export default withStyles(styles)(NodeDeploymentMonitorComponent);
