import { Dashboard } from "components/monitoring/dashboard/types";
import {
	DASHBOARD_ACTION,
	DashboardGetFailedAction,
	DashboardGetSuccessAction,
	DashboardSaveAction,
	DashboardSaveFailedAction,
	DashboardSaveSuccessAction
} from "modules/dashboard/types";

// export const dashboardGet = (): DashboardGetAction => ({
// 	type: DASHBOARD_ACTION.GET
// });

export const dashboardGetSuccess = (
	dashboards: Dashboard[]
): DashboardGetSuccessAction => ({
	type: DASHBOARD_ACTION.GET_SUCCESS,
	payload: {
		dashboards
	}
});

export const dashboardGetFailed = (
	errorMsg: string
): DashboardGetFailedAction => ({
	type: DASHBOARD_ACTION.GET_FAILED,
	payload: {
		errorMsg
	}
});

export const dashboardSave = (dashboard: Dashboard): DashboardSaveAction => ({
	type: DASHBOARD_ACTION.SAVE,
	payload: {
		dashboard
	}
});

export const dashboardSaveSuccess = (
	dashboards: Dashboard[]
): DashboardSaveSuccessAction => ({
	type: DASHBOARD_ACTION.SAVE_SUCCESS,
	payload: {
		dashboards
	}
});

export const dashboardSaveFailed = (
	errorMsg: string
): DashboardSaveFailedAction => ({
	type: DASHBOARD_ACTION.SAVE_FAILED,
	payload: {
		errorMsg
	}
});
