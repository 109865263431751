import { Cluster } from "components/management/cluster/types";
import { Node } from "components/management/node/types";
import { Dashboard } from "components/monitoring/dashboard/types";
import cloneDeep from "lodash/cloneDeep";
import { Layout } from "react-grid-layout";
import {
	DASHBOARD_CHARTS_TOP_OFFSET,
	DASHBOARD_NO_OF_COLUMNS,
	DEFAULT_DASHBOARD_CONFIG,
	DASHBOARD_NO_OF_ROWS
} from "components/monitoring/dashboard/const";

export default class DashboardUtils {
	static arrayToMap(dashboards: Dashboard[] = []): Map<string, Dashboard> {
		return dashboards.reduce<Map<string, Dashboard>>(
			(
				dashboardMap: Map<string, Dashboard>,
				dashboard: Dashboard
			): Map<string, Dashboard> => {
				return dashboardMap.set(dashboard.name, dashboard);
			},
			new Map<string, Dashboard>()
		);
	}

	static mapToArray(dashboardMap: Map<string, Dashboard>): Dashboard[] {
		return Array.from(dashboardMap.values());
	}

	static getDashboardName(cluster: Cluster, node?: Node) {
		return `cluster-${cluster.id}${node ? "nodes" : ""}`;
	}

	static getDefaultConfiguration(name: string) {
		let defaultConfig = cloneDeep(DEFAULT_DASHBOARD_CONFIG);
		defaultConfig.name = name;
		return defaultConfig;
	}

	static getGridSize(tileSize: string): {
		columns: number;
		rowHeight: number;
	} {
		const visibleInitialDashHeight =
			window.innerHeight - DASHBOARD_CHARTS_TOP_OFFSET;

		switch (tileSize) {
			case "sm":
				return {
					columns: DASHBOARD_NO_OF_COLUMNS.SM,
					rowHeight: visibleInitialDashHeight / DASHBOARD_NO_OF_ROWS.SM
				};
			case "md":
				return {
					columns: DASHBOARD_NO_OF_COLUMNS.MD,
					rowHeight: visibleInitialDashHeight / DASHBOARD_NO_OF_ROWS.MD
				};
			case "lg":
			default:
				return {
					columns: DASHBOARD_NO_OF_COLUMNS.LG,
					rowHeight: visibleInitialDashHeight / DASHBOARD_NO_OF_ROWS.LG
				};
		}
	}

	static getCoordinates(tileSize: string, position: number): Layout {
		const { columns } = DashboardUtils.getGridSize(tileSize);

		const x = position % columns;
		const y = parseInt(`${position / columns}`, 10);

		return {
			i: "",
			x: x,
			y: y,
			w: 1,
			h: 1
		};
	}

	static getChartPosition(layout: Layout, tileSize: string): number {
		const { columns } = DashboardUtils.getGridSize(tileSize);
		return layout.y * columns + layout.x;
	}
}
