import {
	DeleteForever,
	DeviceHub,
	MoreVert,
	SettingsBackupRestore
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
	Avatar,
	CardHeader,
	Divider,
	Grid,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Tab,
	Tabs,
	Typography
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Menu from "@mui/material/Menu";
import { WithStyles, withStyles, WithTheme } from "@mui/styles";
import { AppState } from "AppState";
import { AxiosError } from "axios";
import { clusterListFetchRequested } from "components/management/cluster/actions";
import ClusterFormComponent from "components/management/cluster/clusterForm/ClusterFormComponent";
import { DEFAULT_CLUSTER } from "components/management/cluster/const";
import { Cluster } from "components/management/cluster/types";
import { hostListFetchRequested } from "components/management/host/actions";
import { Host } from "components/management/host/types";
import { nodeListFetchRequested } from "components/management/node/actions";
import JobListComponent from "components/management/node/jobs/JobListComponent";
import { nodeCreateWizardShow } from "components/management/node/nodeDeploymentDialog/actions";
import { Node } from "components/management/node/types";
import DashboardComponent from "components/monitoring/dashboard/DashboardComponent";
import { BlinkingBadge } from "components/sharedComponents/BlinkingBadge/BlinkingBadge";
import { GMDialogService } from "components/sharedComponents/dialog/DialogService";
import LogViewer from "components/sharedComponents/logViewer/LogViewerComponent";
import { showSnackbar } from "components/sharedComponents/snackbar/actionCreators";
import {
	SNACKBAR_TYPE,
	SnackbarActionPayload
} from "components/sharedComponents/snackbar/types";
import { DatabasePlus } from "mdi-material-ui";
import ClustersAPI from "modules/api/ClustersAPI";
import JobsService from "modules/jobs/jobsService";
import { Job, JOB_STATUS } from "modules/jobs/types";
import { MetricsStoreState } from "modules/metricsStore/storeTypes";
import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { createSelector } from "reselect";
import { styles } from "./styles";
import { makeJoinedNodesSelector } from "modules/metricsStore/selectors";

// component local state interface
interface LocalState {
	anchorEl: any;
	activeTab: number;
}

// PROPS
interface ReduxStateProps {
	cluster: Cluster;
	committedTransactions: number;
	nodeList: Node[];
	hostList: Host[];
	hasRunningJobs: boolean;
	joinedNodes: number;
}

interface DispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
	nodeCreateWizardShow: (cluster: Cluster) => void;
	reloadClusters: () => void;
	reloadNodes: (clusterID: number) => void;
	reloadHosts: (clusterID: number) => void;
}

type Props = ReduxStateProps &
	DispatchProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

// COMPONENT
class ClusterManagerComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		this.state = {
			anchorEl: null,
			activeTab: 0
		};
	}

	onRecoverClick = () => {
		const { cluster } = this.props;

		if (cluster.id) {
			this.props.showSnackbar({
				msg: `Recovering cluster '${cluster.name}'...`
			});
			ClustersAPI.recover(cluster.id).then(
				(job: Job) => {
					JobsService.monitorJob(job.id).then(
						() => {
							this.props.showSnackbar({
								msg: `Cluster '${cluster.name}' successfully recovered`
							});
						},
						(err: any) => {
							console.log(
								"cluster recover error",
								err,
								err.executionInfo.error.msg
							);

							this.props.showSnackbar({
								msg: `Failed to recover cluster '${cluster.name}', ${
									err.executionInfo?.error?.msg || ""
								}`
							});
						}
					);
				},
				(err: any) => {
					console.error("Cluster recover error:", err);
				}
			);
		}

		this.setState({
			anchorEl: null
		});
	};

	onDeleteClick = (force: boolean = false) => {
		const { cluster } = this.props;

		const message = force
			? `All nodes in this cluster will deleted and all data will be lost. If something fails, nodes will still be removed from Galera Manager database but they still might exist in cloud provider, so please check and manually delete them in your cloud provider console.`
			: `All nodes in this cluster will deleted and all data will be lost.`;

		GMDialogService.showConfirm({
			title: force
				? `Force delete cluster '${cluster.name}'?`
				: `Delete cluster '${cluster.name}'?`,
			message,
			confirmText: force ? "Force delete" : "Delete",
			declineText: "Cancel",
			destructiveConfirm: true
		}).then(
			() => {
				ClustersAPI.delete(cluster, force).then(
					(job: Job) => {
						console.log("Cluster delete job id", job);

						JobsService.monitorJob(job.id).then(
							() => {
								this.props.showSnackbar({
									msg: `Cluster '${cluster.name}' successfully deleted`
								});

								const { pathname } = this.props.location;

								// redirect to /clusters if this cluster still open
								if (
									pathname.includes(`/clusters/${cluster.id}/`) ||
									pathname.endsWith(`/clusters/${cluster.id}`)
								) {
									this.props.history.push(`/clusters`);
								}

								this.props.reloadClusters();
								// todo:
								// this.props.reloadNodes(cluster.id || 0);
								// this.props.reloadHosts(cluster.id || 0);
							},
							(err: any) => {
								console.error("Cluster deletion job monitor error:", err);

								this.props.showSnackbar({
									msg: `Cluster '${cluster.name}' deletion job error.`,
									snackbarType: SNACKBAR_TYPE.ERROR
								});
							}
						);
					},
					(err: AxiosError) => {
						console.error("Error deleting cluster:", err);

						this.props.showSnackbar({
							msg: `Cluster '${cluster.name}' could not be deleted`,
							snackbarType: SNACKBAR_TYPE.ERROR
						});
					}
				);

				this.props.showSnackbar({
					msg: `Cluster '${cluster.name}' is being deleted`
				});
			},
			() => {}
		);

		this.setState({
			anchorEl: null
		});
	};

	onAddNodeClick = () => {
		this.props.nodeCreateWizardShow(this.props.cluster);
		this.setState({ anchorEl: null });
	};

	onMoreClick = (event: any) => {
		// isExpanded menu
		this.setState({ anchorEl: event.currentTarget });
	};

	onMenuClose = () => {
		this.setState({ anchorEl: null });
	};

	render(): React.ReactNode {
		const {
			classes,
			theme,
			committedTransactions,
			cluster,
			hasRunningJobs,
			joinedNodes
		} = this.props;
		const { activeTab, anchorEl } = this.state;

		if (!this.state) return false;

		const disableRecover =
			joinedNodes !== 0 || !cluster.nodes || cluster.nodes?.length === 0;

		return (
			<Card className={classes.card}>
				<CardHeader
					avatar={
						<Avatar
							style={{ backgroundColor: theme.palette.primary.main }}
							aria-label="Cluster"
						>
							<DeviceHub />
						</Avatar>
					}
					action={
						<>
							<IconButton
								data-cy="cluster-manager-more-button"
								onClick={this.onMoreClick}
								size="large"
							>
								<MoreVert />
							</IconButton>
							<Menu
								id="long-menu"
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={this.onMenuClose}
							>
								<MenuItem
									onClick={this.onAddNodeClick}
									data-cy="cluster-manager-add-node-button"
								>
									<ListItemIcon>
										<DatabasePlus />
									</ListItemIcon>
									<ListItemText color={"primary"} primary="Add node" />
								</MenuItem>

								<Divider variant={"middle"} />

								{!cluster.unmanaged && [
									<MenuItem
										key="recover-cluster-button"
										disabled={disableRecover}
										onClick={this.onRecoverClick}
										color={"secondary"}
									>
										<ListItemIcon>
											<SettingsBackupRestore />
										</ListItemIcon>
										<ListItemText
											primary={"Recover cluster"}
											secondary={
												disableRecover
													? "Can recover only when all nodes are stopped"
													: ""
											}
										/>
									</MenuItem>,
									<Divider key="divider" variant={"middle"} />
								]}
								<MenuItem
									data-cy="cluster-manager-delete-button"
									onClick={() => {
										this.onDeleteClick();
									}}
									color={"secondary"}
								>
									<ListItemIcon>
										<DeleteIcon />
									</ListItemIcon>
									<ListItemText primary="Delete cluster" />
								</MenuItem>
								<MenuItem
									data-cy="cluster-manager-force-delete-button"
									onClick={() => {
										this.onDeleteClick(true);
									}}
								>
									<ListItemIcon>
										<DeleteForever />
									</ListItemIcon>
									<ListItemText primary="Force delete cluster" />
								</MenuItem>
							</Menu>
						</>
					}
					title={
						<Grid container direction="row" spacing={1}>
							<Grid item>
								<Typography variant="body2">{cluster.name}</Typography>
							</Grid>
							<Grid item>
								<Typography variant="body2" color="textSecondary">
									({committedTransactions} transactions)
								</Typography>
							</Grid>
						</Grid>
					}
					subheader={cluster.sharedConfig.node.dbEngine}
				/>
				<Divider style={{ margin: 0 }} />
				<Tabs
					value={activeTab}
					onChange={(event: ChangeEvent<{}>, newValue: any) => {
						// console.log("onChange", event, newValue);
						this.setState((state: LocalState) => ({
							...state,
							activeTab: newValue
						}));
					}}
					variant="fullWidth"
				>
					<Tab label={<Typography data-cy="monitor-tab">Monitor</Typography>} />
					<Tab label={<Typography data-cy="logs-tab">Logs</Typography>} />
					<Tab
						label={
							<Typography data-cy="configuration-tab">Configuration</Typography>
						}
					/>
					<Tab
						label={
							hasRunningJobs ? (
								<BlinkingBadge variant="dot" color="primary">
									<Typography>Jobs</Typography>
								</BlinkingBadge>
							) : (
								<Typography>Jobs</Typography>
							)
						}
					/>
				</Tabs>
				<CardContent
					className={activeTab === 1 ? classes.noPaddingCardContent : ""}
				>
					{(activeTab === 0 && <DashboardComponent cluster={cluster} />) ||
						(activeTab === 1 && (
							<Grid container item sm style={{ height: 590 }}>
								<LogViewer cluster={cluster} />
							</Grid>
						)) ||
						(activeTab === 2 && (
							<ClusterFormComponent
								cluster={cluster}
								readOnly={true}
								authorizedKeysSubtitle={
									"Below is the list of currently active authorized keys in this cluster."
								}
							/>
						)) ||
						(activeTab === 3 && <JobListComponent cluster={cluster} />)}
				</CardContent>
			</Card>
		);
	}
}

// selectors
const makeClusterSelector = () =>
	createSelector(
		(state: AppState) => state.clusterList,
		(state: AppState, props: Props) => props.match.params.clusterID,
		(state: AppState, props: Props) => props.history,
		(clusterList: Cluster[], clusterID: number, history): Cluster => {
			const cluster = clusterList.find(
				(cluster: Cluster) => cluster.id === clusterID
			);
			if (cluster) {
				return cluster;
			} else {
				console.warn(`Cluster not found.`);
				history.push("/clusters");
				return DEFAULT_CLUSTER;
				// throw Error(`Cluster ${clusterName} not found.`);
			}
		}
	);

// selectors
const makeNodeListSelector = () =>
	createSelector(
		(state: AppState) => state.nodeList,
		(state: AppState, props: Props) => props.match.params.clusterID,
		(nodeMap: Map<number, Node[]>, clusterID: number): Node[] =>
			nodeMap.get(clusterID) || []
	);

// selectors
const makeHostListSelector = () =>
	createSelector(
		(state: AppState) => state.hostList,
		(state: AppState, props: Props) => props.match.params.clusterID,
		(hostMap: Map<number, Host[]>, clusterID: number): Host[] =>
			hostMap.get(clusterID) || []
	);

const makeCommittedTransactionsSelector = () =>
	createSelector(
		(state: AppState) => state.metrics,
		(state: AppState) => state.clusterList,
		(state: AppState, props: Props) => props.match.params.clusterID,
		(
			metricsStore: MetricsStoreState,
			clusterList: Cluster[],
			clusterID: number
		): number => {
			const cluster = clusterList.find(
				(cluster: Cluster) => cluster.id === clusterID
			);
			if (cluster) {
				const lastCommitted =
					metricsStore.wsrepLastCommittedMetrics[cluster.name];
				return lastCommitted ? lastCommitted.value : 0;
			} else {
				return 0;
			}
		}
	);

const makeHasRunningJobs = () =>
	createSelector(
		[
			makeClusterSelector(),
			(state: AppState) => state.jobMonitor.runningJobList
		],
		(cluster: Cluster, jobList: Job[]) =>
			jobList.some(
				(job: Job) =>
					job.meta.cluster_id === cluster.id &&
					job.executionInfo.status === JOB_STATUS.RUNNING
			)
	);

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState, props: Props) => {
	const committedTransactionsSelector = makeCommittedTransactionsSelector();
	const nodeListSelector = makeNodeListSelector();
	const hostListSelector = makeHostListSelector();
	const clusterSelector = makeClusterSelector();
	const hasRunningJobsSelector = makeHasRunningJobs();
	const joinedNodesSelector = makeJoinedNodesSelector();

	return {
		committedTransactions: committedTransactionsSelector(state, props),
		cluster: clusterSelector(state, props),
		nodeList: nodeListSelector(state, props),
		hostList: hostListSelector(state, props),
		hasRunningJobs: hasRunningJobsSelector(state, props),
		joinedNodes: joinedNodesSelector(state, props.match.params.clusterID)
	};
};

const mapGlobalDispatchToProps = (dispatch: any) => ({
	showSnackbar: (snackbar: SnackbarActionPayload) => {
		dispatch(showSnackbar(snackbar));
	},
	nodeCreateWizardShow: (cluster: Cluster) => {
		dispatch(nodeCreateWizardShow(cluster));
	},
	reloadNodes: (clusterID: number) => {
		dispatch(nodeListFetchRequested(clusterID));
	},
	reloadHosts: (clusterID: number) => {
		dispatch(hostListFetchRequested(clusterID));
	},
	reloadClusters: () => {
		dispatch(clusterListFetchRequested());
	}
});

export default withStyles(styles, { withTheme: true })(
	connect(
		mapGlobalStateToProps,
		mapGlobalDispatchToProps
	)(ClusterManagerComponent)
);
