import React, { CSSProperties, HTMLAttributes } from "react";
import Select, { createFilter } from "react-select";
import { WithStyles, WithTheme } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import TextField, { BaseTextFieldProps } from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { ValueContainerProps } from "react-select/src/components/containers";
import { ControlProps } from "react-select/src/components/Control";
import { MenuProps, NoticeProps } from "react-select/src/components/Menu";
import { OptionProps } from "react-select/src/components/Option";
import { PlaceholderProps } from "react-select/src/components/Placeholder";
import { SingleValueProps } from "react-select/src/components/SingleValue";
import { ValueType } from "react-select/src/types";
import { DistributiveOmit } from "@mui/types";
import { styles } from "./styles";
import { List } from "react-virtualized";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Database, Server } from "mdi-material-ui";
import { AVAILABLE_METRICS } from "components/monitoring/dashboard/AddElement/Autocomplete/availableMetrics";
import { ChartMetric } from "components/monitoring/charts/const";

interface OptionType {
	label: string;
	value: string;
}

function NoOptionsMessage(props: NoticeProps<OptionType, false>) {
	console.log("noOptionsMessage", props);
	return (
		<Typography
			color="textSecondary"
			className={props.selectProps.classes.noOptionsMessage}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

type InputComponentProps = Pick<BaseTextFieldProps, "inputRef"> &
	HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
	return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<OptionType, false>) {
	const {
		children,
		innerProps,
		innerRef,
		selectProps: { classes, TextFieldProps }
	} = props;

	return (
		<TextField
			fullWidth
			InputProps={{
				inputComponent,
				inputProps: {
					className: classes.input,
					ref: innerRef,
					children,
					...innerProps
				}
			}}
			{...TextFieldProps}
		/>
	);
}

function Option(props: OptionProps<OptionType, false>) {
	return (
		<>
			<ListItem
				style={{ height: 50 }}
				button
				ref={props.innerRef}
				selected={props.isFocused}
				{...props.innerProps}
			>
				<ListItemIcon>
					{props.data.label.startsWith("mysql") ? <Database /> : <Server />}
				</ListItemIcon>
				<ListItemText
					primary={props.data.value}
					primaryTypographyProps={{ noWrap: true }}
					secondary={props.data.label}
					secondaryTypographyProps={{ noWrap: true }}
				/>
			</ListItem>
		</>
	);
}

type MuiPlaceholderProps = DistributiveOmit<
	PlaceholderProps<OptionType, false>,
	"innerProps"
> &
	Partial<Pick<PlaceholderProps<OptionType, false>, "innerProps">>;

function Placeholder(props: MuiPlaceholderProps) {
	const { selectProps, innerProps = {}, children } = props;
	return (
		<Typography
			color="textSecondary"
			className={selectProps.classes.placeholder}
			{...innerProps}
		>
			{children}
		</Typography>
	);
}

function SingleValue(props: SingleValueProps<OptionType>) {
	return (
		<Typography
			className={props.selectProps.classes.singleValue}
			{...props.innerProps}
		>
			{props.data.value.replace("node_", "host_")}
		</Typography>
	);
}

function ValueContainer(props: ValueContainerProps<OptionType, false>) {
	return (
		<div className={props.selectProps.classes.valueContainer}>
			{props.children}
		</div>
	);
}

// function MultiValue(props: MultiValueProps<OptionType>) {
// 	return (
// 		<Chip
// 			tabIndex={-1}
// 			label={props.children}
// 			className={clsx(props.selectProps.classes.chip, {
// 				[props.selectProps.classes.chipFocused]: props.isFocused
// 			})}
// 			onDelete={props.removeProps.onClick}
// 			deleteIcon={<CancelIcon {...props.removeProps} />}
// 		/>
// 	);
// }

function Menu(props: MenuProps<OptionType, false>) {
	return (
		<Paper
			square
			className={props.selectProps.classes.paper}
			{...props.innerProps}
		>
			{props.children}
		</Paper>
	);
}

function MenuList(props: any) {
	const calculateHeight = (childrenCount: number) => {
		let height = childrenCount * 50;

		if (height > 300) height = 300;

		if (height === 0) height = 50;

		return height;
	};

	// console.log("MenuList", props, props.children.length);

	return (
		<List
			style={{ width: "100%" }}
			rowCount={props.children.length || 1}
			rowHeight={50}
			width={550}
			height={calculateHeight(props.children.length || 1)}
			rowRenderer={(param: {
				key: any;
				index: number;
				isScrolling: boolean;
				isVisible: boolean;
				style: any;
			}): any => {
				return (
					<div key={param.key} style={param.style}>
						{props.children[param.index]}
					</div>
				);
			}}
		/>
	);
}

const components = {
	Control,
	Menu,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer,
	MenuList
};

interface LocalState {
	selectedMetric: ValueType<OptionType, false> | undefined;
	metrics: OptionType[];
}

interface LocalProps {
	onChanged: (metric: ChartMetric) => void;
	clusterName: string;
}

type Props = LocalProps &
	WithStyles<typeof styles> &
	WithTheme &
	HTMLAttributes<HTMLDivElement>;

class Autocomplete extends React.PureComponent<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		this.state = {
			selectedMetric: undefined,
			metrics: AVAILABLE_METRICS.map((metric: any) => ({
				value: metric.name,
				label: metric.table
			}))
		};
	}

	// fetchMetrics = () => {
	// 	let promQLFilters: string[] = [`gmd_cluster="${this.props.clusterName}"`];
	//
	// 	const promQLFilter = promQLFilters && `{${promQLFilters.join(",")}}`;
	//
	// 	Axios.get(
	// 		`${Config.getInstance().prometheus_url}
	// 		/api/v1/targets/metadata?match_target=${promQLFilter}`
	// 	)
	// 		.then((response: AxiosResponse) => {
	// 			console.log("metricsSet response", response);
	//
	// 			let metricsSet: Set<string> = new Set();
	// 			let metrics: any[] = [];
	//
	// 			const data = response.data.data;
	//
	// 			data.forEach((metricMeta: any) => {
	// 				if (
	// 					!metricsSet.has(metricMeta.metric) &&
	// 					(metricMeta.metric.startsWith("mysql") ||
	// 						metricMeta.metric.startsWith("node"))
	// 				) {
	// 					metricsSet.add(metricMeta.metric);
	// 					metrics.push(metricMeta);
	// 				}
	// 			});
	//
	// 			const sortedMetrics = metrics.sort((a: any, b: any) => {
	// 				if (a.metric < b.metric) {
	// 					return -1;
	// 				}
	// 				if (a.metric > b.metric) {
	// 					return 1;
	// 				}
	// 				return 0;
	// 			});
	//
	// 			sortedMetrics &&
	// 				this.setState({
	// 					metrics: sortedMetrics.map((metric: any) => ({
	// 						value: metric.metric,
	// 						label: metric.help
	// 					}))
	// 				});
	// 		})
	// 		.catch((err: AxiosError) => {
	// 			console.error("Failed to fetch Prometheus target metadata:", err);
	// 		});
	// };

	filter = createFilter({
		stringify: (obj: any) => {
			return obj.data.value.replace("node_", "host_");
		}
	});

	selectStyles = {
		input: (base: CSSProperties) => ({
			...base,
			color: this.props.theme.palette.text.primary,
			"& input": {
				font: "inherit"
			}
		})
	};

	render():
		| React.ReactElement
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const { classes } = this.props;
		const { selectedMetric, metrics } = this.state;

		const handleChangeSingle = (value: ValueType<OptionType, false>) => {
			const selectedValue = value as OptionType;
			this.setState({ selectedMetric: value });
			selectedValue &&
				this.props.onChanged({
					name: selectedValue.value,
					table: selectedValue.label
				});
		};

		return (
			<div className={classes.root}>
				{metrics && (
					<Select
						classes={classes}
						style={this.selectStyles}
						inputId="react-select-single"
						TextFieldProps={{
							label: "Metric",
							InputLabelProps: {
								htmlFor: "react-select-single",
								shrink: true
							}
						}}
						// noOptionsMessage={(obj: { inputValue: string }) => {
						// 	return "No options...";
						// }}
						placeholder={`Search ${metrics.length} metrics...`}
						options={metrics}
						components={components}
						value={selectedMetric}
						onChange={handleChangeSingle}
						getOptionLabel={(option: OptionType) => {
							return option.value;
						}}
						filterOption={this.filter}
					/>
				)}
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Autocomplete);
