import { User } from "components/users/types";
import { Credentials, JwtData } from "modules/auth/types";

export interface AuthState {
	isAuthenticated: boolean;
	isLoggingIn: boolean;
	loginErrorMsg?: string;
	isLoggingOut: boolean;
	logoutErrorMsg?: string;
	userData?: User;
	jwtAccessToken?: JwtData; // used only for WebSocket auth
	jwtAccessTokenErrorMsg?: string; // used only for WebSocket auth
}

export enum AUTH_ACTION {
	BOOT_CHECK = "AUTH/BOOT_CHECK",
	LOGIN = "AUTH/LOGIN",
	LOGIN_SUCCESS = "AUTH/LOGIN_SUCCESS",
	LOGIN_FAILED = "AUTH/LOGIN_FAILED",
	LOGOUT = "AUTH/LOGOUT",
	LOGOUT_SUCCESS = "AUTH/LOGOUT_SUCCESS",
	LOGOUT_FAILED = "AUTH/LOGOUT_SUCCESSFUL",
	FETCH_JWT = "AUTH/FETCH_JWT",
	FETCH_JWT_SUCCESS = "AUTH/FETCH_JWT_SUCCESS",
	FETCH_JWT_FAILED = "AUTH/FETCH_JWT_FAILED"
}

export interface AuthBootCheck {
	type: AUTH_ACTION.BOOT_CHECK;
}

export interface AuthLogin {
	type: AUTH_ACTION.LOGIN;
	payload: {
		credentials: Credentials;
	};
}

export interface AuthLoginSuccess {
	type: AUTH_ACTION.LOGIN_SUCCESS;
	payload: {
		userData: User;
	};
}

export interface AuthLoginFailed {
	type: AUTH_ACTION.LOGIN_FAILED;
	payload: {
		errorMsg: string;
	};
}

export interface AuthLogout {
	type: AUTH_ACTION.LOGOUT;
}

export interface AuthLogoutSuccess {
	type: AUTH_ACTION.LOGOUT_SUCCESS;
}

export interface AuthLogoutFailed {
	type: AUTH_ACTION.LOGOUT_FAILED;
	payload: {
		errorMsg: string;
	};
}

export interface AuthFetchJWT {
	type: AUTH_ACTION.FETCH_JWT;
}

export interface AuthFetchJWTSuccess {
	type: AUTH_ACTION.FETCH_JWT_SUCCESS;
	payload: {
		jwtTokenData: JwtData;
	};
}

export interface AuthFetchJWTFailed {
	type: AUTH_ACTION.FETCH_JWT_FAILED;
	payload: {
		errorMsg: string;
	};
}

export type AuthAction =
	| AuthBootCheck
	| AuthLogin
	| AuthLoginSuccess
	| AuthLoginFailed
	| AuthLogout
	| AuthLogoutSuccess
	| AuthLogoutFailed
	| AuthFetchJWT
	| AuthFetchJWTSuccess
	| AuthFetchJWTFailed;
