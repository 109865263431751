import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip
} from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { AppState } from "AppState";
import { CLUSTER_TYPE } from "components/management/cluster/clusterCreateWizard/types";
import { Cluster } from "components/management/cluster/types";
import ClusterUtils from "components/management/cluster/utils";
import {
	EC2SpecificSettings,
	Host,
	HOST_SYSTEM,
	HOST_TYPE
} from "components/management/host/types";
import HostUtils from "components/management/host/utils";
import { Node, NODE_DB_ENGINE } from "components/management/node/types";
import { CONNECTION_TEST_STATUS } from "components/sharedComponents/connectionTestDialog/types";
import DbEngineConfigButtonComponent from "components/sharedComponents/dbEngineConfigEditor/DbEngineConfigButtonComponent";
import EC2ConfigComponent from "components/sharedComponents/ec2Config/EC2ConfigComponent";
import SSHKeysEditor from "components/sharedComponents/SSHKeysEditor/SSHKeysEditorComponent";
import SupportMatrix from "modules/supportMatrix/SupportMatrix";
import * as React from "react";
import { ChangeEvent, FormEvent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import { createSelector } from "reselect";
import { styles } from "./styles";

interface LocalState {
	formValidation: {
		nodeName?: {
			isInvalid: boolean;
			message: string;
		};
		hostName?: {
			isInvalid: boolean;
			message: string;
		};
		segment?: {
			isInvalid: boolean;
			message: string;
		};
		databaseSizeGiB?: {
			isInvalid: boolean;
			message: string;
		};
		sshAddress?: {
			isInvalid: boolean;
			message: string;
		};
		sshPort?: {
			isInvalid: boolean;
			message: string;
		};
		hostSystem?: {
			isInvalid: boolean;
			message: string;
		};
	};
	supportedSystems: HOST_SYSTEM[];
	isNodeNameLinkEnabled: boolean;
}

interface LocalProps {
	cluster: Cluster;
	node?: Node;
	host: Host;
	readOnly?: boolean;
	onNodeChange?: (node: Node) => void;
	onHostChange?: (host: Host) => void;
	onSubmit?: () => void;
	multipleDeploymentMode?: boolean;
	onToggleGeneralLog?: () => void;
	sshTestStatus?: CONNECTION_TEST_STATUS;
}

// PROPS
interface ReduxStateProps {
	nodeNames: string[];
	hostNames: string[];
}

type Props = LocalProps &
	ReduxStateProps &
	WithStyles<typeof styles> &
	RouteComponentProps<any, StaticContext, any>;

class NodeFormComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		this.state = {
			formValidation: {},
			supportedSystems: SupportMatrix.getSupportedHostSystems(
				props.node?.dbEngine ||
					// todo props.cluster?.nodeDefaults.dbEngine ||
					NODE_DB_ENGINE.MARIADB_10_3
			),
			isNodeNameLinkEnabled: true
		};
	}

	onSubmit = (e: FormEvent) => {
		e.preventDefault();
		// console.log("onSubmit", e);

		if (!SupportMatrix.isSystemSupported(this.props.host.system)) {
			this.setState({
				formValidation: {
					hostSystem: {
						isInvalid: true,
						message: `${this.props.host.system} is no longer supported by Galera Manager`
					}
				}
			});

			return;
		}

		if (this.props.readOnly !== false && this.props.onSubmit) {
			this.props.onSubmit();
		}
	};

	onHostNameChange = (e: ChangeEvent) => {
		const {
			onHostChange,
			onNodeChange,
			multipleDeploymentMode,
			hostNames,
			host,
			node
		} = this.props;

		const field = e.target as HTMLFormElement;

		onHostChange && onHostChange({ ...host, name: field.value as string });
		!this.state.isNodeNameLinkEnabled &&
			node &&
			onNodeChange &&
			onNodeChange({ ...node, hostID: field.value });

		!multipleDeploymentMode && hostNames.includes(field.value)
			? field.setCustomValidity("Host name already in use")
			: field.setCustomValidity("");

		const isValid = field.checkValidity();

		this.setState((state: LocalState) => ({
			formValidation: {
				...state.formValidation,
				hostName: {
					isInvalid: !isValid,
					message: isValid ? "" : field.validationMessage
				}
			}
		}));
	};

	setHostName(hostName: string) {
		const { onHostChange, host } = this.props;

		onHostChange && onHostChange({ ...host, name: hostName });
		// node && onNodeChange && onNodeChange({ ...node, hostID: hostName });
	}

	render() {
		const {
			cluster,
			node,
			host,
			multipleDeploymentMode,
			onHostChange,
			onNodeChange,
			readOnly,
			nodeNames,
			sshTestStatus
		} = this.props;

		const { supportedSystems, isNodeNameLinkEnabled, formValidation } =
			this.state;

		const clusterType = ClusterUtils.getClusterType(cluster);

		return (
			<>
				<form
					id={`nodeForm${readOnly ? "-readonly" : ""}`}
					onSubmit={this.onSubmit}
				>
					{node && host && (
						<>
							<Grid container direction="column">
								<Grid container direction="row" spacing={2}>
									<Grid item sm={6} xs={12}>
										<FormControl
											onInvalid={(e: FormEvent): void => {
												e.preventDefault();
												const form = e.target as HTMLFormElement;

												this.setState((state: LocalState) => ({
													formValidation: {
														...state.formValidation,
														nodeName: {
															isInvalid: true,
															message: form.validationMessage
														}
													}
												}));
											}}
											error={formValidation.nodeName?.isInvalid}
											fullWidth={true}
											required
										>
											<TextField
												error={formValidation.nodeName?.isInvalid}
												margin="dense"
												required
												label={
													multipleDeploymentMode
														? "Node name prefix"
														: "Node name"
												}
												autoFocus={!readOnly}
												inputProps={{
													readOnly: readOnly,
													minLength: 3,
													maxLength: 20,
													"data-cy": "node-name"
												}}
												autoComplete="off"
												value={node.name}
												helperText={formValidation.nodeName?.message}
												onChange={(e: ChangeEvent) => {
													const field = e.target as HTMLFormElement;

													isNodeNameLinkEnabled && this.onHostNameChange(e);

													onNodeChange &&
														onNodeChange({
															...node,
															name: field.value,
															hostID: isNodeNameLinkEnabled
																? field.value
																: node?.hostID
														});

													console.log("nodeNames", nodeNames);
													!multipleDeploymentMode &&
													nodeNames.includes(field.value)
														? field.setCustomValidity(
																"Node name already in use"
														  )
														: field.setCustomValidity("");

													const isValid = field.checkValidity();

													this.setState((state: LocalState) => ({
														formValidation: {
															...state.formValidation,
															nodeName: {
																isInvalid: !isValid,
																message: isValid ? "" : field.validationMessage
															}
														}
													}));
												}}
											/>
										</FormControl>
									</Grid>

									{clusterType !== CLUSTER_TYPE.MANAGED && (
										<Grid
											sx={{ display: { xs: "none", md: "block" } }}
											item
											sm={6}
											xs={12}
										/>
									)}

									<Grid item sm={6} xs={12}>
										<FormControl
											required
											fullWidth={true}
											margin="normal"
											error={formValidation.hostSystem?.isInvalid}
										>
											<InputLabel htmlFor="host-system">Host system</InputLabel>
											<Select
												required
												renderValue={(value: string) => {
													return value;
												}}
												data-cy="host-system-select-container"
												fullWidth={true}
												value={host.system}
												onChange={(e) => {
													const value = e.target.value as string;

													onHostChange &&
														onHostChange({
															...host,
															system: HostUtils.getHostSystemEnum(value)
														});

													this.setState((state: LocalState) => ({
														formValidation: {
															...state.formValidation,
															hostSystem: undefined
														}
													}));
												}}
												inputProps={{
													id: "host-system",
													"data-cy": "host-system-select",
													readOnly: readOnly
												}}
											>
												{supportedSystems.map((system: string) => (
													<MenuItem key={system} value={system}>
														{system}
													</MenuItem>
												))}
											</Select>
											{formValidation.hostSystem?.isInvalid && (
												<FormHelperText>
													{formValidation.hostSystem?.message}
												</FormHelperText>
											)}
										</FormControl>
									</Grid>
									<Grid item sm={6} xs={12}>
										<FormControl
											onInvalid={(e: FormEvent): void => {
												e.preventDefault();
												const form = e.target as HTMLFormElement;

												this.setState((state: LocalState) => ({
													...state,
													formValidation: {
														...state.formValidation,
														segment: {
															isInvalid: true,
															message: form.validationMessage
														}
													}
												}));
											}}
											error={formValidation.segment?.isInvalid}
											fullWidth={true}
											required
										>
											<TextField
												error={formValidation.segment?.isInvalid}
												helperText={formValidation.segment?.message}
												fullWidth={true}
												type="number"
												required
												margin="dense"
												label="Segment"
												value={host.segment}
												onChange={(e) => {
													const field = e.target as HTMLInputElement;
													const value = parseInt(field.value);

													onHostChange &&
														onHostChange({
															...host,
															segment: value
														});

													const isValid = field.checkValidity();

													this.setState((state: LocalState) => ({
														formValidation: {
															...state.formValidation,
															segment: {
																isInvalid: !isValid,
																message: isValid ? "" : field.validationMessage
															}
														}
													}));
												}}
												inputProps={{
													id: "host-segment",
													"data-cy": "host-segment",
													readOnly: readOnly,
													min: 0
												}}
											/>
										</FormControl>
									</Grid>
									{host.type !== HOST_TYPE.UNMANAGED && (
										<Grid item sm={6} xs={12}>
											<DbEngineConfigButtonComponent
												config={node.userConfig}
												// todo inheritedConfig={cluster?.nodeDefaults.userConfig}
												inheritedConfig={""}
												readOnly={readOnly || false}
												onChange={(config: string) => {
													node &&
														onNodeChange &&
														onNodeChange({ ...node, userConfig: config });
												}}
												subtitleText={
													readOnly
														? "This is currently active custom DB engine configuration"
														: "Enter custom DB engine configuration that will be applied to this node"
												}
											/>
										</Grid>
									)}
									{readOnly && (
										<Grid item sm={6} xs={12}>
											<Tooltip
												title={`Click to ${
													node.settings.logs.generalLog.enabled
														? "disable"
														: "enable"
												} general log tracking`}
											>
												<FormControlLabel
													control={
														<Checkbox
															data-cy="toggle-general-log"
															checked={node.settings.logs.generalLog.enabled}
															onChange={() => {
																this.props.onToggleGeneralLog &&
																	this.props.onToggleGeneralLog();
															}}
															value="startNode"
															color="primary"
														/>
													}
													label={
														"General log (consumes large amounts of disk space on GMD host over time)"
													}
												/>
											</Tooltip>
										</Grid>
									)}
									{/*</Grid>*/}
									{host.type === HOST_TYPE.EC2 && host.hostTypeSpecific && (
										<>
											<EC2ConfigComponent
												config={host.hostTypeSpecific}
												readOnly={readOnly}
												requirements={{
													minRAM: node
														? node.dbEngine === NODE_DB_ENGINE.MYSQL_8_0
															? 2048
															: 1024
														: 1024
												}}
												onChange={(ec2Config: EC2SpecificSettings) => {
													console.log("onEC2 config change", ec2Config);

													onHostChange &&
														onHostChange({
															...host,
															hostTypeSpecific: {
																...ec2Config
															}
														});
												}}
											/>
										</>
									)}
									{(readOnly || host.type === HOST_TYPE.UNMANAGED) && (
										<>
											<Grid item sm={6} xs={12}>
												<FormControl
													onInvalid={(e: FormEvent): void => {
														e.preventDefault();
														const form = e.target as HTMLFormElement;

														this.setState((state: LocalState) => ({
															formValidation: {
																...state.formValidation,
																sshAddress: {
																	isInvalid: true,
																	message: form.validationMessage
																}
															}
														}));
													}}
													error={formValidation.sshAddress?.isInvalid}
													fullWidth={true}
													required
												>
													<TextField
														fullWidth={true}
														margin="dense"
														required
														error={formValidation.sshAddress?.isInvalid}
														helperText={formValidation.sshAddress?.message}
														label="SSH Address"
														value={host.ssh && host.ssh.address}
														inputProps={{ readOnly }}
														onChange={(e: ChangeEvent) => {
															const field = e.target as HTMLFormElement;
															const address = field.value as string;

															onHostChange &&
																onHostChange({
																	...host,
																	ssh: {
																		address,
																		port: host.ssh?.port || "22"
																	},
																	db: {
																		address,
																		port: host.db?.port || "3306"
																	}
																});

															const isValid = field.checkValidity();

															this.setState((state: LocalState) => ({
																formValidation: {
																	...state.formValidation,
																	sshAddress: {
																		isInvalid: !isValid,
																		message: isValid
																			? ""
																			: field.validationMessage
																	}
																}
															}));
														}}
													/>
												</FormControl>
											</Grid>
											<Grid item sm={6} xs={12}>
												<FormControl
													onInvalid={(e: FormEvent): void => {
														e.preventDefault();
														const form = e.target as HTMLFormElement;

														this.setState((state: LocalState) => ({
															formValidation: {
																...state.formValidation,
																sshPort: {
																	isInvalid: true,
																	message: form.validationMessage
																}
															}
														}));
													}}
													error={formValidation.sshPort?.isInvalid}
													fullWidth={true}
													required
												>
													<TextField
														fullWidth={true}
														type="number"
														required
														error={formValidation.sshPort?.isInvalid}
														helperText={formValidation.sshPort?.message}
														margin="dense"
														label="SSH Port"
														value={host.ssh && host.ssh.port}
														inputProps={{ readOnly, min: 0, max: 65535 }}
														onChange={(e: ChangeEvent) => {
															const field = e.target as HTMLFormElement;
															const port = field.value as string;

															onHostChange &&
																onHostChange({
																	...host,
																	ssh: {
																		address: host.ssh?.address || "",
																		port
																	}
																});

															const isValid = field.checkValidity();

															this.setState((state: LocalState) => ({
																formValidation: {
																	...state.formValidation,
																	sshPort: {
																		isInvalid: !isValid,
																		message: isValid
																			? ""
																			: field.validationMessage
																	}
																}
															}));
														}}
													/>
												</FormControl>
											</Grid>
										</>
									)}
									<Grid item sm={6} xs={12}>
										<SSHKeysEditor
											clusterType={clusterType}
											authorizedKeys={host.authorizedKeys}
											sshTestStatus={sshTestStatus}
											inheritedAuthorizedKeys={
												cluster ? cluster.sharedConfig.host.authorizedKeys : []
											}
											readOnly={readOnly || false}
											onAdd={(publicKey: string): void => {
												onHostChange &&
													onHostChange({
														...host,
														authorizedKeys: [...host.authorizedKeys, publicKey]
													});
											}}
											onRemove={(deletedKey: string): void => {
												const filteredKeys = host.authorizedKeys.filter(
													(publicKey: string) => publicKey !== deletedKey
												);

												onHostChange &&
													onHostChange({
														...host,
														authorizedKeys: filteredKeys
													});
											}}
										/>
									</Grid>
								</Grid>
							</Grid>
						</>
					)}
				</form>
			</>
		);
	}
}

const makeNodeNamesSelector = () =>
	createSelector(
		(state: AppState) => state.nodeList,
		(state: AppState, props: LocalProps) => props.cluster,
		(nodeList: Map<number, Node[]>, cluster?: Cluster): string[] => {
			if (cluster && cluster.id) {
				return nodeList.get(cluster.id)?.map((node: Node) => node.name) || [];
			} else {
				return [];
			}
		}
	);

const makeHostNamesSelector = () =>
	createSelector(
		(state: AppState) => state.hostList,
		(state: AppState, props: LocalProps) => props.cluster,
		(hostList: Map<number, Host[]>, cluster?: Cluster): string[] => {
			if (cluster && cluster.id) {
				return hostList.get(cluster.id)?.map((host: Host) => host.name) || [];
			} else {
				return [];
			}
		}
	);

const mapGlobalStateToProps = (state: AppState, props: LocalProps) => {
	const nodeNamesSelector = makeNodeNamesSelector();
	const hostNamesSelector = makeHostNamesSelector();

	return {
		nodeNames: nodeNamesSelector(state, props),
		hostNames: hostNamesSelector(state, props)
	};
};

export default withStyles(styles, { withTheme: true })(
	withRouter(connect(mapGlobalStateToProps, {})(NodeFormComponent))
);
