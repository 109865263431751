import { Host } from "components/management/host/types";
import AuthAPI from "modules/api/AuthAPI";
import AppEnvironment from "modules/appEnvironment";
import { Subject } from "rxjs";

export interface HostLog {
	"channel-type": string;
	"host-id": number;
	"cluster-id": number;
	level: string;
	msg: string;
	time: Date;
}

class WSService {
	private static instance: WSService;

	static getInstance(): WSService {
		if (!WSService.instance) {
			WSService.instance = new WSService();
		}

		return WSService.instance;
	}

	private _connection: WebSocket;
	private _hostLogSubscriptions: Map<string, Subject<HostLog>> = new Map();

	// private _obs: Subject<HostLog> = new Subject<HostLog>();

	private constructor() {
		this._connection = new WebSocket(
			`${AppEnvironment.getWsHostAddress()}/v2/ws`
		);

		this._connection.onopen = (event: Event) => {
			console.log("WebSock connection open!");
			this.authenticate();
		};

		this._connection.onerror = (event: Event) => {
			console.error("WebSocket connection error!", event);
		};

		this._connection.onmessage = (event: MessageEvent) => {
			// console.log("WebSocket message received", JSON.parse(event.data));

			const message = JSON.parse(event.data);

			switch (message.type) {
				case "auth":
					this.authenticate();
					break;
				case "log":
					this.handleLogMessages(message);
					break;
			}
		};
	}

	private async authenticate() {
		await AuthAPI.refreshWsAccessToken().then((jwt: string) => {
			this._connection.send(
				JSON.stringify({
					type: "auth",
					value: jwt
				})
			);
		});
	}

	private handleLogMessages(data: any) {
		// console.log("log message received", data.value);
		const log = data.value as HostLog;
		// console.log("log message");
		const subscriptionStr = `cluster:${log["cluster-id"]}:host:${log["host-id"]}`;
		// console.log(
		// 	"subscription str",
		// 	subscriptionStr,
		// 	this._hostLogSubscriptions
		// );
		if (this._hostLogSubscriptions.has(subscriptionStr) && log.msg.trim()) {
			const observable = this._hostLogSubscriptions.get(subscriptionStr);
			observable && observable.next(log);
		}
	}

	public subscribeToLogs(subscription: string): Subject<HostLog> {
		// console.log("subscribe to logs", subscription, this._connection);
		this._connection.send(
			JSON.stringify({
				type: "subscribe",
				value: subscription
			})
		);

		const logs: Subject<HostLog> = new Subject<HostLog>();
		// logs.pipe(throttleTime(1000));

		this._hostLogSubscriptions.set(subscription, logs);

		return logs;
	}

	public unsubscribeFromLogs(host: Host) {
		// send unsubscribe message
		this._connection.send(
			JSON.stringify({
				type: "unsubscribe",
				value: `host:${host.name}`
			})
		);

		// delete observable
		if (this._hostLogSubscriptions.has(host.name)) {
			this._hostLogSubscriptions.delete(host.name);
		}
	}
}

export default WSService;
