import Axios, { AxiosResponse, CancelToken } from "axios";
import AppEnvironment from "modules/appEnvironment";
import APIUtils from "modules/api/utils";
import { deattribute } from "kitsu-core";
import { Job, SerializedJob } from "modules/jobs/types";

const ENDPOINT = "jobs";

class JobsApi {
	// static async fetchList(): Promise<Job[]> {
	// 	try {
	// 		const jobsResponse = await Axios.get(APIUtils.getUrl(ENDPOINT));
	//
	// 		return jobsResponse.data.data.map((item: SerializedJob) =>
	// 			deattribute({ ...item })
	// 		);
	// 	} catch (e) {
	// 		throw new Error(APIUtils.APIErrorResponseHandler(e));
	// 	}
	// }

	// used for fetching jobs in running state, or if job_ids are provided - list of those jobs
	static async fetchMonitoredJobList(job_ids?: number[]): Promise<Job[]> {
		let params: any = {
			"page[number]": 1,
			"page[size]": 100
		};

		if (job_ids) {
			params["filter[job_id]"] = job_ids.join(",");
		} else {
			params["filter[job_status]"] = "running";
		}

		try {
			const jobsResponse = await Axios.get(APIUtils.getUrl(ENDPOINT), {
				params
			});

			// console.log("monitored jobList", jobsResponse.data.data);

			return jobsResponse.data.data.map((item: SerializedJob) =>
				deattribute({ ...item })
			);
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async fetchPaginatedList(
		page: number,
		pageSize: number,
		clusterID: number,
		nodeID?: number,
		hostID?: number,
		cancelToken?: CancelToken
	): Promise<{ list: Job[]; totalCount: number }> {
		try {
			const jobsResponse = await Axios.get(APIUtils.getUrl(ENDPOINT), {
				cancelToken: cancelToken,
				params: {
					"page[number]": page,
					"page[size]": pageSize,
					"filter[cluster_id]": clusterID,
					"filter[node_id]": nodeID,
					"filter[host_id]": hostID
				}
			});

			const list = jobsResponse.data.data.map((item: SerializedJob) =>
				deattribute({ ...item })
			);

			const totalCount: number = jobsResponse.data.meta.total;

			return { list, totalCount };
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async fetch(id: number): Promise<AxiosResponse> {
		return await Axios.get(
			`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/jobs/${id}`
		);
	}
}

export default JobsApi;
