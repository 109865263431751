import { Job } from "modules/jobs/types";

export interface JobMonitorState {
	jobsToMonitor: number[];
	monitoredJobList: Job[];
	runningJobList: Job[];
}

export enum JOB_MONITOR_ACTION {
	START = "JOB_MONITOR/START",
	GET_RUNNING_SUCCESS = "JOB_MONITOR/GET_RUNNING_SUCCESS",
	GET_MONITORED_SUCCESS = "JOB_MONITOR/GET_MONITORED_SUCCESS",
	JOB_MONITOR_STOP = "JOB_MONITOR/STOP"
}

export interface JobMonitorStartAction {
	type: JOB_MONITOR_ACTION.START;
	payload: {
		jobId: number;
	};
}

export interface JobMonitorGetSuccessAction {
	type:
		| JOB_MONITOR_ACTION.GET_RUNNING_SUCCESS
		| JOB_MONITOR_ACTION.GET_MONITORED_SUCCESS;
	payload: {
		jobList: Job[];
	};
}

export interface JobMonitorStopAction {
	type: JOB_MONITOR_ACTION.JOB_MONITOR_STOP;
	payload: {
		jobId: number;
	};
}

export type JobMonitorAction =
	| JobMonitorStartAction
	| JobMonitorGetSuccessAction
	| JobMonitorStopAction;
