import React from "react";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
// MUI
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	IconButton,
	Paper,
	Step,
	StepLabel,
	Stepper,
	Tooltip,
	Typography
} from "@mui/material";
import { WithStyles, WithTheme } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { styles } from "./styles";
// redux
import { connect } from "react-redux";
import { AppState } from "AppState";
import {
	CLUSTER_TYPE,
	ClusterCreateWizardState
} from "components/management/cluster/clusterCreateWizard/types";
import { clusterCreateWizardHide } from "components/management/cluster/clusterCreateWizard/actions";

import { HOST_TYPE } from "components/management/host/types";
import { showSnackbar } from "components/sharedComponents/snackbar/actionCreators";
import { Cluster } from "components/management/cluster/types";
import { SnackbarActionPayload } from "components/sharedComponents//snackbar/types";
import { nodeCreateWizardShow } from "components/management/node/nodeDeploymentDialog/actions";
import ClusterFormComponent from "components/management/cluster/clusterForm/ClusterFormComponent";
import { DEFAULT_CLUSTER } from "components/management/cluster/const";
import ClustersAPI from "modules/api/ClustersAPI";
import { Job } from "modules/jobs/types";
import JobsService from "modules/jobs/jobsService";
import { clusterListFetchRequested } from "components/management/cluster/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { ContentCopy, Database, Server } from "mdi-material-ui";
import { checkIfDesktop } from "utils/checkIfDesktop";

import { GMDialogService } from "components/sharedComponents/dialog/DialogService";

// component local state interface
interface LocalState {
	cluster: Cluster;
	activeStep: number;
	inProgress: boolean;
	hasErrorHappened: boolean;
	errorMessage: string;
	clusterType: CLUSTER_TYPE;
	addedPublicKeysCheckbox: boolean;
	formIsChanged: boolean;
}

// PROPS
interface ReduxStateProps {
	clusterCreateWizard: ClusterCreateWizardState;
}

interface ReduxDispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
	clusterCreateWizardHide: () => void;
	clusterListFetchRequested: () => void;
	nodeCreateWizardShow: (cluster: Cluster) => void;
}

type Props = ReduxStateProps &
	ReduxDispatchProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

class ClusterCreateDialogComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);
		this.state = { ...this.getDefaultState() };
	}

	resetState() {
		this.setState({ ...this.getDefaultState() });
	}

	getDefaultState(): LocalState {
		let defaultState: LocalState = {
			cluster: DEFAULT_CLUSTER,
			activeStep: 0,
			inProgress: false,
			hasErrorHappened: false,
			errorMessage: "",
			clusterType: CLUSTER_TYPE.MANAGED,
			addedPublicKeysCheckbox: false,
			formIsChanged: false // if form is modified, ask user if they want to abandon changes they made
		};

		// @ts-ignore
		// const pk = sshpk.generatePrivateKey("ed25519");

		// console.log(
		// 	"private key",
		// 	pk,
		// 	pk.toString("openssh"),
		// 	pk.toPublic().toString("ssh")
		// );

		// defaultState.cluster.sharedConfig.host.privateKey = pk.toString("openssh");
		// defaultState.cluster.sharedConfig.host.authorizedKeys = [
		// 	pk.toPublic().toString("ssh")
		// ];

		return defaultState;
	}

	hideWizard = () => {
		this.props.clusterCreateWizardHide();
		setTimeout(() => {
			this.resetState();
		}, 500);
	};

	onCloseAttempt(step: number): void {
		if (step === 0 || step === 1) {
			if (this.state.formIsChanged) {
				GMDialogService.showConfirm({
					title: "Unsaved changes",
					message:
						"Are you sure you want to exit the dialog? Changes will be lost.",
					confirmText: "Exit",
					declineText: "Keep editing"
				}).then(
					() => {
						this.hideWizard();
					},
					() => {}
				);
			} else {
				this.hideWizard();
			}
		}
	}

	// set formIsChanged to true, so user is asked to confirm that they want to leave the form editing
	setFormIsChangedToTrue = () => {
		if (this.state.formIsChanged === false) {
			this.setState((state: LocalState) => ({
				...state,
				formIsChanged: true
			}));
		}
	};

	onClusterTypeSelected = (clusterType: CLUSTER_TYPE) => {
		console.log("cluster type selected", clusterType);

		switch (clusterType) {
			case CLUSTER_TYPE.MANAGED:
				this.setState({ activeStep: 1 });
				break;

			case CLUSTER_TYPE.HYBRID:
				this.setState((state: LocalState) => ({
					clusterType: CLUSTER_TYPE.HYBRID,
					activeStep: 1,
					cluster: {
						...state.cluster,
						sharedConfig: {
							...state.cluster.sharedConfig,
							host: {
								...state.cluster.sharedConfig.host,
								type: HOST_TYPE.UNMANAGED
							}
						}
					}
				}));
				break;

			case CLUSTER_TYPE.MONITORED:
				this.setState((state: LocalState) => ({
					clusterType: CLUSTER_TYPE.MONITORED,
					activeStep: 1,
					cluster: {
						...state.cluster,
						unmanaged: true,
						sharedConfig: {
							...state.cluster.sharedConfig,
							host: {
								...state.cluster.sharedConfig.host,
								type: HOST_TYPE.UNMANAGED
							},
							node: {
								...state.cluster.sharedConfig.node,
								rootPassword: ""
							}
						}
					}
				}));
				break;
		}
	};

	async createCluster(cluster: Cluster) {
		console.log("add cluster", cluster);
		this.setState({
			inProgress: true,
			hasErrorHappened: false,
			errorMessage: ""
		});

		try {
			const response: { job: Job; cluster: Cluster } = await ClustersAPI.create(
				cluster
			);

			console.log("create job started", response);
			const { job, cluster: createdCluster } = response;
			JobsService.monitorJob(job.id).then(
				(job: Job) => {
					console.log("Cluster created!", createdCluster.name, job);

					this.setState({
						inProgress: false,
						activeStep: 2,
						cluster: createdCluster
					});
					this.props.clusterListFetchRequested();
				},
				(job: Job) => {
					console.error("Cluster create job failed", createdCluster.name, job);

					this.setState({
						inProgress: false,
						hasErrorHappened: true,
						errorMessage: `Cluster creation failed. ${job.executionInfo.details}`
					});
				}
			);
		} catch (e: any) {
			console.error("Cluster creation error.", e);
			this.setState({
				inProgress: false,
				hasErrorHappened: true,
				errorMessage: `Failed to create a cluster. ${e.message}`
			});
		}
	}

	render() {
		const { isOpen } = this.props.clusterCreateWizard;
		const { classes } = this.props;

		const {
			activeStep,
			cluster,
			inProgress,
			hasErrorHappened,
			errorMessage,
			clusterType,
			addedPublicKeysCheckbox,
			formIsChanged
		} = this.state;

		console.log("clusterType", clusterType);

		const stepper = (
			<>
				<Stepper
					activeStep={activeStep}
					classes={{ root: classes.stepperRoot }}
				>
					<Step key={1}>
						<StepLabel>{"Select cluster type"}</StepLabel>
					</Step>

					<Step key={2}>
						<StepLabel>{"Configure"}</StepLabel>
					</Step>

					<Step key={3}>
						<StepLabel>{"Finish"}</StepLabel>
					</Step>
				</Stepper>
			</>
		);

		const cancelAddClusterButton = (
			<Button
				disabled={inProgress}
				variant="outlined"
				size="medium"
				onClick={(): void => {
					this.onCloseAttempt(activeStep);
				}}
			>
				Cancel
			</Button>
		);

		const wizardButtons = (
			<>
				{(activeStep === 0 && <>{cancelAddClusterButton}</>) ||
					(activeStep === 1 && (
						<>
							{cancelAddClusterButton}
							<Button
								disabled={inProgress}
								type="submit"
								form="clusterForm"
								color="primary"
								variant="contained"
								size="medium"
							>
								{inProgress && (
									<CircularProgress
										className={classes.buttonLoader}
										size={20}
									/>
								)}
								{inProgress ? "Creating..." : "Create"}
							</Button>
						</>
					)) ||
					(activeStep === 2 && (
						<>
							<div style={{ flexGrow: 1 }} />
							<Button
								variant="outlined"
								size="medium"
								disabled={
									clusterType !== CLUSTER_TYPE.MANAGED &&
									!addedPublicKeysCheckbox
								}
								onClick={() => {
									this.hideWizard();
									this.props.nodeCreateWizardShow(cluster);
								}}
							>
								Add nodes
							</Button>
							<Button
								color="primary"
								variant="contained"
								size="medium"
								disabled={
									clusterType !== CLUSTER_TYPE.MANAGED &&
									!addedPublicKeysCheckbox
								}
								onClick={() => {
									this.hideWizard();
								}}
							>
								Finish
							</Button>
						</>
					))}
			</>
		);

		const clusterTypeSelectionStep = (
			<Grid container direction="column" spacing={2}>
				<Grid item>
					<Paper
						variant="outlined"
						className={classes.paperContainer}
						onClick={() => {
							this.onClusterTypeSelected(CLUSTER_TYPE.MANAGED);
						}}
						data-cy="cluster-type-managed"
					>
						<Grid container direction="row">
							<Grid
								container
								direction="column"
								item
								spacing={1}
								xs
								className={classes.typeSelectionPaper}
							>
								<Grid item>
									<Typography variant="h5">
										Deploy fully managed cluster
									</Typography>
									<Typography variant="subtitle2" color="textSecondary">
										Requires AWS EC2 account
									</Typography>
									<ul style={{ margin: 0 }}>
										<Typography variant="body2">
											<li>nodes monitoring</li>
										</Typography>
										<Typography variant="body2">
											<li>deploy nodes on selected cloud provider (AWS EC2)</li>
										</Typography>
										<Typography variant="body2">
											<li>deploy multiple nodes at once</li>
										</Typography>
										<Typography variant="body2">
											<li>start / stop nodes</li>
										</Typography>
										<Typography variant="body2">
											<li>enable / disable node general log</li>
										</Typography>
									</ul>
								</Grid>
							</Grid>

							<Grid item>
								<Grid item>
									<Box
										className={classes.manageBox}
										style={{
											borderTopRightRadius: "4px",
											marginBottom: "1px"
										}}
									>
										<Database className={classes.manageIcon} />
										<Typography
											variant="subtitle1"
											className={classes.manageText}
										>
											MANAGED NODE
										</Typography>
									</Box>
								</Grid>
								<Grid item>
									<Box
										className={classes.manageBox}
										style={{ borderBottomRightRadius: "4px" }}
									>
										<Server className={classes.manageIcon} />
										<Typography
											variant="subtitle2"
											className={classes.manageText}
										>
											MANAGED HOST
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item>
					<Paper
						variant="outlined"
						className={classes.paperContainer}
						onClick={() => {
							this.onClusterTypeSelected(CLUSTER_TYPE.HYBRID);
						}}
						data-cy="cluster-type-hybrid"
					>
						<Grid container direction="row">
							<Grid
								container
								direction="column"
								item
								spacing={1}
								xs
								className={classes.typeSelectionPaper}
							>
								<Grid item>
									<Typography variant="h5">
										Deploy cluster on user-provided hosts
									</Typography>
									<Typography variant="subtitle2" color="textSecondary">
										Requires access to user-provided hosts
									</Typography>
									<ul style={{ margin: 0 }}>
										<Typography variant="body2">
											<li>nodes monitoring</li>
										</Typography>
										<Typography variant="body2">
											<li>
												install and uninstall node on the user-provided hosts
											</li>
										</Typography>
										<Typography variant="body2">
											<li>start / stop nodes</li>
										</Typography>
										<Typography variant="body2">
											<li>enable / disable node general log</li>
										</Typography>
									</ul>
								</Grid>
							</Grid>

							<Grid item>
								<Grid item>
									<Box
										className={classes.manageBox}
										style={{
											borderTopRightRadius: "4px",
											marginBottom: "1px"
											// borderBottom: "white 1px solid"
										}}
									>
										<Database className={classes.manageIcon} />
										<Typography
											variant="subtitle2"
											className={classes.manageText}
										>
											MANAGED NODE
										</Typography>
									</Box>
								</Grid>
								<Grid item>
									<Box
										className={classes.monitorBox}
										style={{ borderBottomRightRadius: "4px" }}
									>
										<Server className={classes.monitorIcon} />
										<Typography
											variant="subtitle2"
											className={classes.monitorText}
										>
											MONITORED HOST
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item>
					<Paper
						onClick={() => {
							this.onClusterTypeSelected(CLUSTER_TYPE.MONITORED);
						}}
						data-cy="cluster-type-monitored"
						variant="outlined"
						className={classes.paperContainer}
					>
						<Grid container direction="row">
							<Grid
								container
								direction="column"
								item
								spacing={1}
								xs
								className={classes.typeSelectionPaper}
							>
								<Grid item>
									<Typography variant="h5">Monitor existing cluster</Typography>
									<Typography variant="subtitle2" color="textSecondary">
										Requires access to existing Galera Cluster nodes
									</Typography>
									<ul style={{ margin: 0 }}>
										<Typography variant="body2">
											<li>chart node and host metrics</li>
										</Typography>
										<Typography variant="body2">
											<li>view node logs</li>
										</Typography>
									</ul>
								</Grid>
							</Grid>

							<Grid item>
								<Grid item>
									<Box
										className={classes.monitorBox}
										style={{
											borderTopRightRadius: "4px",
											marginBottom: "1px"
										}}
									>
										<Database className={classes.monitorIcon} />
										<Typography
											variant="subtitle2"
											className={classes.monitorText}
										>
											MONITORED NODE
										</Typography>
									</Box>
								</Grid>
								<Grid item>
									<Box
										className={classes.monitorBox}
										style={{ borderBottomRightRadius: "4px" }}
									>
										<Server className={classes.monitorIcon} />
										<Typography
											variant="subtitle2"
											className={classes.monitorText}
										>
											MONITORED HOST
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		);

		const configureStep = (
			<>
				<ClusterFormComponent
					cluster={cluster}
					readOnly={inProgress}
					authorizedKeysSubtitle={
						"Add public keys which will be added to .authorized_keys on all hosts in this cluster."
					}
					onSubmit={(cluster: Cluster) => {
						console.log("clusterSubmitted", cluster);
						this.createCluster(cluster);
					}}
					onChange={(cluster: Cluster) => {
						// console.log("onChange", cluster);
						this.setFormIsChangedToTrue();
						this.setState({ cluster });
					}}
				/>
			</>
		);

		const finishStep = (
			<>
				<Typography variant="subtitle1">
					Cluster has been successfully created.
				</Typography>
				{clusterType !== CLUSTER_TYPE.MANAGED && (
					<>
						<br />
						<Typography variant="subtitle1">
							Galera Manager must have root access to your nodes. To make sure
							it does, please add following public key to the
							/root/.ssh/authorized_keys file on all nodes.
						</Typography>
						<Typography className={classes.publicKey}>
							{cluster.sharedConfig.host.authorizedKeys &&
								cluster.sharedConfig.host.authorizedKeys[0]}
							<Tooltip title={"Copy key"}>
								<IconButton
									onClick={() => {
										if (
											navigator.clipboard &&
											cluster.sharedConfig.host.authorizedKeys
										) {
											navigator.clipboard
												.writeText(cluster.sharedConfig.host.authorizedKeys[0])
												.then(
													() => {
														this.props.showSnackbar({
															msg: "Public key copied to clipboard"
														});
													},
													(err: Error) => {
														console.error("Password copy error:", err);
														this.props.showSnackbar({
															msg: "Couldn't copy public key to clipboard"
														});
													}
												);
										} else {
											this.props.showSnackbar({
												msg: "Couldn't copy public key"
											});
										}
									}}
									edge="end"
									aria-label="Copy"
									size="large"
								>
									<ContentCopy className={classes.copyKeyIcon} />
								</IconButton>
							</Tooltip>
						</Typography>
						<br />
						<Typography>
							<FormControlLabel
								control={
									<Checkbox
										checked={addedPublicKeysCheckbox}
										color="primary"
										onChange={() => {
											this.setState((state: LocalState) => ({
												addedPublicKeysCheckbox: !state.addedPublicKeysCheckbox
											}));
										}}
									/>
								}
								label="I have added public key to /root/.ssh/authorized_keys file on all nodes"
							/>
						</Typography>
					</>
				)}
			</>
		);

		const dialogContent = (
			<>
				<DialogContent>
					{stepper}
					{hasErrorHappened && (
						<Grid item>
							<Typography variant="subtitle2" color="error">
								{errorMessage}
							</Typography>
						</Grid>
					)}
					{(activeStep === 0 && clusterTypeSelectionStep) ||
						(activeStep === 1 && configureStep) ||
						(activeStep === 2 && finishStep)}
				</DialogContent>
				<DialogActions sx={{ px: 3, pb: 3 }}>{wizardButtons}</DialogActions>
			</>
		);

		const isDesktop = checkIfDesktop();

		return (
			<>
				<Dialog
					onClose={(event: Event, reason: string) => {
						this.onCloseAttempt(activeStep);
					}}
					scroll="body"
					fullScreen={!isDesktop}
					fullWidth={isDesktop}
					maxWidth={activeStep === 2 ? "sm" : "md"}
					open={isOpen}
				>
					<DialogTitle>Add cluster</DialogTitle>
					{dialogContent}
				</Dialog>
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({
	clusterCreateWizard: state.clusterCreateWizard
});

const mapGlobalDispatchToProps = (dispatch: any) => ({
	clusterCreateWizardHide: (): void => {
		dispatch(clusterCreateWizardHide());
	},
	clusterListFetchRequested: (): void => {
		dispatch(clusterListFetchRequested());
	},
	nodeCreateWizardShow: (cluster: Cluster) => {
		dispatch(nodeCreateWizardShow(cluster));
	},
	showSnackbar: (snackbar: SnackbarActionPayload) => {
		dispatch(showSnackbar(snackbar));
	}
});

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			mapGlobalStateToProps,
			mapGlobalDispatchToProps
		)(ClusterCreateDialogComponent)
	)
);
