import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) =>
	createStyles({
		paperContainer: {
			cursor: "pointer",
			"&:hover": {
				backgroundColor: "#f6f6f6"
			},
			"&:active": {
				backgroundColor: "#f0f0f0"
			}
		},
		typeSelectionPaper: {
			padding: "10px"
			// backgroundColor: "#f0f0f0"
		},
		stepperRoot: {
			padding: "0 0 20px 0"
		}
	});
