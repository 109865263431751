import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

const drawerWidth = 240;

export const styles = (theme: Theme) =>
	createStyles({
		root: {
			display: "flex"
		},
		drawer: {
			[theme.breakpoints.up("sm")]: {
				// width: drawerWidth,
				// flexShrink: 0
			}
		},
		appBar: {
			backgroundColor: "#424242",
			[theme.breakpoints.up("sm")]: {
				zIndex: theme.zIndex.drawer + 1
			}
		},
		menuButton: {
			marginRight: 20
		},
		drawerPaper: {
			width: drawerWidth
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(1),
			[theme.breakpoints.up("sm")]: {
				paddingLeft: theme.spacing(2)
			}
		}
	});
