import JobsApi from "modules/api/JobsApi";
import { isMonitorEnabledSelector } from "modules/metricsStore/selectors";
import { METRICS_ACTION } from "modules/metricsStore/storeTypes";
import { call, delay, put, select } from "typed-redux-saga";
import { takeLatest } from "redux-saga/effects";
import { AppState } from "AppState";
import {
	jobMonitorGetMonitoredSucceeded,
	jobMonitorGetRunningSucceeded
} from "modules/jobs/monitor/actions";

function* monitorRunningJobList() {
	// console.log("Running jobs monitor started");
	let monitor: boolean = yield select(isMonitorEnabledSelector);

	while (monitor) {
		try {
			const runningJobs = yield* call(JobsApi.fetchMonitoredJobList);
			// console.log("running jobs", runningJobs);
			yield put(jobMonitorGetRunningSucceeded(runningJobs));
		} catch (e: any) {
			console.error("Job list monitor error:", e);
		} finally {
			yield delay(2000);
			monitor = yield select(isMonitorEnabledSelector);
		}
	}
}

function* monitorJobList() {
	let monitor: boolean = yield select(isMonitorEnabledSelector);
	// console.log("Jobs monitor started", monitor);

	while (monitor) {
		try {
			const jobsToMonitor: number[] = yield select(monitoredJobsSelector);
			if (jobsToMonitor.length > 0) {
				const monitoredJobs = yield* call(
					JobsApi.fetchMonitoredJobList,
					jobsToMonitor
				);
				// console.log("monitored jobs", monitoredJobs);
				yield put(jobMonitorGetMonitoredSucceeded(monitoredJobs));
			} else {
				// console.log("no jobs to monitor");
			}
		} catch (e: any) {
			console.error("Jobs monitor error:", e);
		} finally {
			yield delay(2000);
			monitor = yield select(isMonitorEnabledSelector);
		}
	}
}

function* JobSideEffects() {
	yield takeLatest(METRICS_ACTION.START_MONITOR, monitorJobList);
	yield takeLatest(METRICS_ACTION.START_MONITOR, monitorRunningJobList);
}

export const monitoredJobsSelector = (state: AppState): number[] =>
	state.jobMonitor.jobsToMonitor;

export default JobSideEffects;
