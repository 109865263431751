import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";

export const styles = (theme: Theme) =>
	createStyles({
		nested: {
			paddingLeft: theme.spacing(8)
		}
	});
