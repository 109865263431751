import Axios, { AxiosError, AxiosResponse } from "axios";
import { Cluster } from "components/management/cluster/types";
import { Node } from "components/management/node/types";
import ChartMessage from "components/monitoring/charts/chartMessage/chartMessage";
import {
	ChartMetric,
	DEFAULT_AGGREGATION,
	DEFAULT_PERIOD,
	DEFAULT_REFRESH_INTERVAL
} from "components/monitoring/charts/const";
import LiveValueComponent from "components/monitoring/charts/timeSeries/liveValue/LiveValueComponent";
import ChartUtils from "components/monitoring/charts/utils";
import { AGGREGATION } from "components/monitoring/dashboard/types";
import { DEFAULT_COLORS } from "components/sharedComponents/logViewer/const";
import { IResponse } from "influx";
import { IResponseSeries, IResultEntry, Row } from "influx/lib/src/results";
import _ from "lodash";
import { DateTime } from "luxon";
import Config from "modules/config/Config";
import PlotComponent from "modules/reactPlotlyPlot";
// @ts-ignore
import { layout, scatter } from "plotly-js-material-design-theme";
import { PlotRelayoutEvent } from "plotly.js";
import * as React from "react";
import { ReactElement } from "react";
import { debounceTime, fromEvent, Subscription } from "rxjs";

interface LocalState {
	traces: any[];
	isLoading: boolean;
}

interface LocalProps {
	containerHeight?: number;
	containerWidth?: number;
	disableRender: boolean;
	dragToMove?: boolean;
	period?: string;
	title: string;
	metric: ChartMetric;
	cluster: Cluster;
	node?: Node;
	aggregation?: AGGREGATION;
	unit?: string;
	dataScaling?: number;
	yAxisMax?: number;
	tileSize?: string;
}

type Props = LocalProps;

interface Snapshot {
	shouldFetchAsyncData: boolean;
	shouldClearExistingData: boolean;
}

class TimeSeriesChartComponent extends React.Component<Props, LocalState> {
	_isMounted = false;
	_scheduledFetch: any;
	_cancelRequest: any;
	_windowSizeObservable: Subscription;
	_lastRequestTimestamp?: number;
	_isRefreshPaused: boolean = false;

	constructor(props: Props) {
		super(props);

		this.state = {
			traces: [],
			isLoading: true
		};

		// rerender after window resize
		this._windowSizeObservable = fromEvent(window, "resize")
			.pipe(debounceTime(500))
			.subscribe(() => {
				this.forceUpdate();
			});
	}

	fetchData() {
		if (!this.props.metric) return;

		// console.log("fetch data");

		this.setState({ isLoading: true });

		const {
			metric,
			cluster,
			node,
			period = DEFAULT_PERIOD,
			// resolution = DEFAULT_RESOLUTION,
			aggregation = DEFAULT_AGGREGATION
		} = this.props;

		const {
			influx_host: host,
			influx_port: port,
			influx_password: password,
			influx_protocol: protocol,
			influx_username: username,
			influx_db: db
		} = Config.getInstance();

		const url = `${protocol}://${host}:${port}/query`;

		const params = {
			q: ChartUtils.buildQuery(
				cluster.name,
				metric,
				aggregation,
				period,
				node?.name
			),
			db
		};

		this._lastRequestTimestamp = DateTime.now().toMillis();

		Axios.get(url, {
			auth: {
				username,
				password
			},
			params,
			cancelToken: new Axios.CancelToken((c: any) => {
				this._cancelRequest = c;
			})
		})
			.then((response: AxiosResponse<IResponse>) => {
				// console.log("response");

				if (!this._isMounted || this._isRefreshPaused) return;

				let traces: any[] = [];

				response.data.results.forEach((result: IResultEntry) => {
					// console.log("result entries", result);

					result.series?.forEach((series: IResponseSeries, index: number) => {
						// console.log("result values", series);

						const node = series.tags ? series.tags["node"] : "unknown";

						// console.log("node name", node);

						let trace: any = {
							line: {
								width: 1.5,
								color: DEFAULT_COLORS[index % 10]
							},
							mode: "lines",
							type: "scatter",
							hoverinfo: "name+y+x",
							x: [],
							y: [],
							name: node
						};

						series.values?.forEach((row: Row) => {
							// trace.x.push(moment.unix(row[0]).toDate());
							// trace.y.push(row[1]);
							// console.log("row", row[0], new Date(row[0]), moment.unix(row[0]));
							trace.x.push(new Date(row[0]));
							if (this.props.dataScaling) {
								trace.y.push(row[1] * this.props.dataScaling);
							} else {
								trace.y.push(row[1]);
							}
						});

						traces.push(scatter(trace));
					});
				});

				// console.log("traces", traces);
				// if (!this._isMounted) return;

				this.setState({
					traces,
					isLoading: false
				});
			})
			.catch((err: AxiosError) => {
				if (!Axios.isCancel(err)) {
					// TODO: uncomment, BKBK
					// console.error(
					// 	"Metrics error response:",
					// 	err.message,
					// 	err.response?.data
					// );
				}
				this.setState({
					isLoading: false
				});
			})
			.finally(() => {
				this._cancelRequest = undefined;
				// console.log("isMounted", this._isMounted);
				if (this._isMounted) {
					this._scheduledFetch = setTimeout(() => {
						// console.log("scheduled fetch");
						this.fetchData();
					}, ChartUtils.getRemainingIntervalTime(this._lastRequestTimestamp));
				}
			});
	}

	getSnapshotBeforeUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>
	): any | null {
		return {
			shouldFetchAsyncData:
				!_.isEqual(prevProps.metric, this.props.metric) ||
				!_.isEqual(prevProps.period, this.props.period) ||
				!_.isEqual(prevProps.cluster, this.props.cluster) ||
				!_.isEqual(prevProps.node, this.props.node) ||
				!_.isEqual(prevProps.aggregation, this.props.aggregation) ||
				!_.isEqual(prevProps.dataScaling, this.props.dataScaling),
			shouldClearExistingData:
				!_.isEqual(prevProps.metric, this.props.metric) ||
				!_.isEqual(prevProps.cluster, this.props.cluster) ||
				!_.isEqual(prevProps.node, this.props.node) ||
				!_.isEqual(prevProps.aggregation, this.props.aggregation) ||
				!_.isEqual(prevProps.dataScaling, this.props.dataScaling)
		};
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>,
		snapshot?: Snapshot
	): void {
		// if metric, cluster or node changed - reload async data
		if (this._isMounted && snapshot) {
			// console.log("snapshot", snapshot);
			if (snapshot.shouldFetchAsyncData) {
				this.clearAsyncRequests();
				console.log("snapshot induced fetch");
				this.fetchData();
			}

			if (snapshot.shouldClearExistingData) {
				this.setState({ traces: [] });
			}
		}
	}

	shouldComponentUpdate(
		nextProps: Readonly<Props>,
		nextState: Readonly<LocalState>,
		nextContext: any
	): boolean {
		const didPropsChange = !_.isEqual(nextProps, this.props);
		const didDataChange = !_.isEqual(this.state.traces, nextState.traces);
		const didStateChange = !_.isEqual(
			this.state.isLoading,
			nextState.isLoading
		);
		// console.log(
		// 	"shouldComponentUpdate",
		// 	didPropsChange,
		// 	didDataChange,
		// 	didStateChange
		// );
		// if (this._isRefreshPaused) {
		// 	console.log("refresh paused");
		// 	return true;
		// } else {
		return didDataChange || didPropsChange || didStateChange;
		// }
	}

	componentDidMount(): void {
		this._isMounted = true;
		this.fetchData();
	}

	componentWillUnmount(): void {
		this._isMounted = false;
		this._windowSizeObservable?.unsubscribe();
		this.clearAsyncRequests();
	}

	clearAsyncRequests(): void {
		if (this._cancelRequest) this._cancelRequest();
		if (this._scheduledFetch) clearTimeout(this._scheduledFetch);
	}

	render(): ReactElement {
		const { traces, isLoading } = this.state;
		const {
			title,
			period,
			metric,
			aggregation,
			cluster,
			node,
			unit,
			yAxisMax,
			dragToMove
		} = this.props;
		// console.log("render chart");
		const isThereAnyData = traces.length !== 0;

		const untypedConfigValues = {
			spikedistance: 200,
			hoverdistance: 200
		};

		const yAxisTitle = unit
			? aggregation === AGGREGATION.DIFFERENTIAL
				? `${unit}/s`
				: unit
			: "";

		let message;

		if (!isThereAnyData) message = "No data";
		if (!isThereAnyData && isLoading) message = "Loading...";
		if (dragToMove) message = "Drag to move";

		return (
			<>
				{message && <ChartMessage message={message} />}

				<LiveValueComponent
					traces={traces.sort(
						(trace1: any, trace2: any) =>
							trace2.y[trace2.y.length - 1] - trace1.y[trace1.y.length - 1]
					)}
					// hostNames={sortedTraces.map((trace: any) => trace.name)}
					metric={metric}
					cluster={cluster}
					node={node}
					// lastValues={sortedTraces.map(
					// 	(trace: any) => trace.y[trace.y.length - 1]
					// )}
					// colors={sortedTraces.map((trace: any) => trace.line.color)}
					differential={aggregation === AGGREGATION.DIFFERENTIAL}
					refreshInterval={DEFAULT_REFRESH_INTERVAL}
				/>
				<PlotComponent
					style={{ height: "100%", width: "100%" }}
					data={traces}
					layout={layout({
						...untypedConfigValues,
						font: {
							size: 10
						},
						autosize: true,
						title: {
							text: title,
							font: {
								size: 14
							}
						},
						margin: { t: 50, l: 30, r: 0, b: 20 },
						showlegend: false,
						yaxis: {
							type: "linear",
							rangemode: "tozero",
							title: yAxisTitle,
							range: [0, yAxisMax],
							fixedrange: true
						},
						xaxis: {
							showticklabels: true,
							linecolor: "rgba(0, 0, 0, 0.3)",
							range:
								(period && ChartUtils.getDateRangeFromPeriod(period)) ||
								undefined,
							fixedrange: true
						}
					})}
					onRelayout={(event: Readonly<PlotRelayoutEvent>) => {
						console.log("relayout event", event);
						// if zoom is reset to default, resume data refresh
						if (event["xaxis.autorange"] || event["yaxis.autorange"]) {
							this._isRefreshPaused = false;
						} else {
							this._isRefreshPaused = true;
							console.log("pause refresh");
						}
					}}
					config={{ displayModeBar: false, scrollZoom: false }}
				/>
			</>
		);
	}
}

export default TimeSeriesChartComponent;
