import {
	PUBLIC_PRELOADER_ACTION,
	PublicPreloadFailedAction,
	PublicPreloadAction,
	PublicPreloadSuccessAction,
	SECURE_PRELOADER_ACTION,
	SecurePreloadFailedAction,
	SecurePreloadAction,
	SecurePreloadSuccessAction
} from "modules/preloader/types";

export const securePreload = (): SecurePreloadAction => ({
	type: SECURE_PRELOADER_ACTION.PRELOAD
});

export const securePreloadSuccess = (): SecurePreloadSuccessAction => ({
	type: SECURE_PRELOADER_ACTION.PRELOAD_SUCCESS
});

export const securePreloadFailed = (
	errorMsg: string
): SecurePreloadFailedAction => ({
	type: SECURE_PRELOADER_ACTION.PRELOAD_FAILED,
	payload: {
		errorMsg
	}
});

export const publicPreload = (): PublicPreloadAction => ({
	type: PUBLIC_PRELOADER_ACTION.PRELOAD
});

export const publicPreloadSuccess = (): PublicPreloadSuccessAction => ({
	type: PUBLIC_PRELOADER_ACTION.PRELOAD_SUCCESS
});

export const publicPreloadFailed = (
	errorMsg: string
): PublicPreloadFailedAction => ({
	type: PUBLIC_PRELOADER_ACTION.PRELOAD_FAILED,
	payload: {
		errorMsg
	}
});
