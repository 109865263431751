import { Grid, Typography } from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { HostLog } from "modules/webSocketHandler/WSService";
import moment from "moment";
import React from "react";
import { styles } from "./styles";

interface LocalProps {
	logLine: HostLog;
}

type Props = LocalProps & WithStyles<typeof styles>;

class DeploymentLogMessage extends React.Component<Props, any> {
	shouldComponentUpdate(
		nextProps: Readonly<Props>,
		nextState: Readonly<any>,
		nextContext: any
	): boolean {
		return (
			this.props.logLine.msg !== nextProps.logLine.msg ||
			this.props.logLine.time !== nextProps.logLine.time
		);
	}

	render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const { logLine, classes } = this.props;

		function renderLine(msg: string, index: number) {
			return (
				<Grid data-cy="log-message" key={index} container direction="row">
					<Typography
						component="span"
						variant="body2"
						data-cy="live-log-message"
						classes={{ body2: classes.logMessagesTypography }}
						className={
							(logLine["channel-type"] === "host-stderr" && classes.errorLog) ||
							""
						}
					>
						{`${moment(logLine.time).format("MMM DD, YYYY HH:mm:ss")} | ${
							logLine["channel-type"]?.padEnd(14, " ") || "galera-manager"
						} | ${msg.trim()}`}
					</Typography>
				</Grid>
			);
		}

		const msgs = logLine.msg.split("\r");

		return msgs
			.filter((msg: string) => {
				return msg.trim() !== "" && msg.trim() !== "\n";
			})
			.map((msg: string, index: number) =>
				renderLine(msg.replace("\n", ""), index)
			);
	}
}

export default withStyles(styles)(DeploymentLogMessage);
