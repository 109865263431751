import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { AppState } from "AppState";
import { userListFetchRequested } from "components/users/actions";
import { User } from "components/users/types";
import React from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { styles } from "./styles";
import {
	Avatar,
	Button,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListSubheader,
	Paper
} from "@mui/material";
import { Person } from "@mui/icons-material";
import { userCreateWizardShow } from "components/users/userCreateWizard/actions";
import UserUtils from "components/users/utils";

// global state props
interface LocalProps {
	userList: User[];
}

// global dispatch props
interface DispatchProps {
	userListFetchRequested: () => void;
	userCreateWizardShow: () => void;
}

type Props = LocalProps &
	DispatchProps &
	WithStyles<typeof styles> &
	RouteComponentProps<any, StaticContext, any>;

// local state
interface LocalState {}

// COMPONENT
class UserListComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		this.props.userListFetchRequested();

		this.state = {};
	}

	render(): React.ReactNode {
		const { classes } = this.props;

		return (
			<>
				<Paper className={classes.root}>
					<List subheader={<ListSubheader>Users</ListSubheader>}>
						{this.props.userList.map((user: User) => {
							return (
								<ListItem
									key={user.id}
									button
									onClick={(): void => {
										this.props.history.push(`/users/${user.name}`);
									}}
								>
									<ListItemAvatar>
										<Avatar>
											<Person />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={user.name}
										secondary={UserUtils.getRoleName(user.role)}
									/>
								</ListItem>
							);
						})}
					</List>
				</Paper>
				<Button
					fullWidth={true}
					onClick={() => {
						console.log("onItemClick");
						this.props.userCreateWizardShow();
					}}
				>
					Create new user
				</Button>
			</>
		);
	}
}

// global state and dispatch mappings
const mapGlobalStateToProps = (state: AppState) => {
	return {
		userList: state.userList
	};
};

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		userListFetchRequested: () => {
			dispatch(userListFetchRequested());
		},
		userCreateWizardShow: () => {
			dispatch(userCreateWizardShow());
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	connect(mapGlobalStateToProps, mapGlobalDispatchToProps)(UserListComponent)
);
