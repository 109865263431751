import moment from "moment";
import { AGGREGATION } from "components/monitoring/dashboard/types";
import {
	ChartMetric,
	DEFAULT_REFRESH_INTERVAL,
	MAX_NUM_OF_POINTS_IN_CHART
} from "components/monitoring/charts/const";
import { DateTime } from "luxon";

class ChartUtils {
	/**
	 * @description Given last refresh timestamp, it returns interval in ms until
	 * 							the next supposed request. Used to maintain a consistent
	 * 							refresh interval
	 * @param {number} lastRequestTimestamp
	 * @returns {number} ms until next request
	 */
	static getRemainingIntervalTime(lastRequestTimestamp?: number): number {
		// console.log("getRemainingIntervalTime", lastRequestTimestamp);
		if (!lastRequestTimestamp) {
			return DEFAULT_REFRESH_INTERVAL;
		} else {
			// get current timestamp
			const now = DateTime.now().toMillis();
			const serverResponseTime = now - lastRequestTimestamp;
			const msUntilNextRequest = DEFAULT_REFRESH_INTERVAL - serverResponseTime;
			// console.log("msUntilNextRequest", msUntilNextRequest);

			if (msUntilNextRequest < 0) {
				// console.log(
				// 	`Server response time (${serverResponseTime}ms) is longer than refresh interval (${DEFAULT_REFRESH_INTERVAL}ms)`
				// );
			}
			// console.log("msUntilNextRequest", msUntilNextRequest);
			return Math.max(msUntilNextRequest, 0);
		}
	}

	static getDateRangeFromPeriod(period: string): any[] {
		const unit = period[period.length - 1];
		const amount = period.substring(0, period.length - 1);

		return [
			moment()
				.subtract(amount, unit as any)
				.toDate(),
			moment().toDate()
		];
	}

	static getTimestampRangeFromPeriod(period: string): {
		from: number;
		to: number;
	} {
		const unit = period[period.length - 1];
		const amount = period.substring(0, period.length - 1);

		return {
			from: moment()
				.utc()
				.subtract(amount, unit as any)
				.unix(),
			to: moment().utc().unix()
		};
	}

	static getGroupSeconds(period: string): number {
		// console.log(
		// 	"getNumberOfSeconds",
		// 	period,
		// 	parseInt(period.replace(/[^0-9]/g, "")),
		// 	period.replace(/[0-9]/g, "")
		// );

		const amount = parseInt(period.replace(/[^0-9]/g, ""));
		const unit = period.replace(/[0-9]/g, "");

		const seconds = moment.duration(amount, unit as any).as("s");

		// console.log(
		// 	"moment duration",
		// 	moment.duration(amount, unit as any),
		// 	moment.duration(amount, unit as any).as("s"),
		// 	Math.ceil(seconds / MAX_NUM_OF_POINTS_IN_CHART)
		// );

		return Math.ceil(seconds / MAX_NUM_OF_POINTS_IN_CHART);
	}

	static buildQuery(
		clusterName: string,
		metric: ChartMetric,
		aggregation: AGGREGATION,
		period: string,
		nodeName?: string
	): string {
		const unit = period[period.length - 1];
		const amount = period.substring(0, period.length - 1);

		const buildWhereStatement = (): string => {
			return `cluster = '${clusterName}' ${
				nodeName ? "AND node = '" + nodeName + "'" : ""
			} AND time > ${moment()
				.subtract(amount, unit as any)
				.valueOf()}000000`;
		};

		if (aggregation === AGGREGATION.RAW) {
			return `SELECT MAX(${metric.name}) FROM ${
				metric.table
			} WHERE ${buildWhereStatement()} GROUP BY node, time(${this.getGroupSeconds(
				period
			)}s)`;
		} else {
			return `SELECT MAX("replicated_derivative") AS "mean_derivative" FROM (SELECT DERIVATIVE(${
				metric.name
			}, 1s) AS "replicated_derivative" FROM ${
				metric.table
			} WHERE ${buildWhereStatement()}) GROUP BY node, time(${this.getGroupSeconds(
				period
			)}s)`;
		}
	}
}

export default ChartUtils;
