import {
	FetchLastCommittedMetricsSuccessAction,
	FetchWsrepLocalStateMetricsSuccessAction,
	METRICS_ACTION,
	StartMonitorMetricsAction,
	StopMonitorMetricsAction
} from "modules/metricsStore/storeTypes";
import {
	WsrepLastCommittedMetric,
	WsrepLocalStateMetric
} from "modules/metricsStore/types";

export const startMetricsMonitor = (): StartMonitorMetricsAction => ({
	type: METRICS_ACTION.START_MONITOR
});

export const stopMetricsMonitor = (): StopMonitorMetricsAction => ({
	type: METRICS_ACTION.STOP_MONITOR
});

export const fetchLastCommittedMetricsSucceeded = (
	metrics: Record<
		string,
		WsrepLastCommittedMetric>
): FetchLastCommittedMetricsSuccessAction => ({
	type: METRICS_ACTION.FETCH_WSREP_LAST_COMMITTED_METRICS_SUCCESS,
	payload: {
		metrics
	}
});

export const fetchWsrepLocalStateMetricsSuccess = (
	metrics: WsrepLocalStateMetric[]
): FetchWsrepLocalStateMetricsSuccessAction => ({
	type: METRICS_ACTION.FETCH_WSREP_LOCAL_STATE_METRICS_SUCCESS,
	payload: {
		metrics
	}
});
