import { Button, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { AppState } from "AppState";
import logo from "assets/images/gc_logo_orange.png";
import { login } from "modules/auth/actions";
import { Credentials } from "modules/auth/types";
import React, { FormEvent } from "react";
import { connect } from "react-redux";
import { styles } from "./styles";

// component local state interface
interface LoginComponentState {
	credentials: Credentials;
}

// PROPS
interface LocalProps {}

interface DispatchProps {
	login: (credentials: Credentials) => void;
}

interface ReduxStateProps {
	isLoggingIn: boolean;
	loginErrorMsg?: string;
}

type LoginComponentProps = LocalProps &
	DispatchProps &
	ReduxStateProps &
	WithStyles<typeof styles>;

// COMPONENT
class LoginComponent extends React.Component<
	LoginComponentProps,
	LoginComponentState
> {
	constructor(props: LoginComponentProps) {
		super(props);

		this.state = {
			credentials: {
				name: "",
				password: ""
			}
		};
	}

	readonly handleClick = (event: FormEvent) => {
		event.preventDefault();

		this.props.login(this.state.credentials);
	};

	render(): React.ReactNode {
		const { credentials } = this.state;
		const { classes, isLoggingIn, loginErrorMsg } = this.props;

		return (
			<>
				<Grid
					container
					alignItems="center"
					alignContent="center"
					direction="column"
				>
					<Grid item>
						<img
							className={classes.logo}
							src={logo}
							alt="Galera Cluster Logo"
						/>
					</Grid>
					<Grid item className={classes.formGridItem}>
						<Card className={classes.formContainer}>
							<form onSubmit={this.handleClick}>
								<CardContent>
									<Typography variant="h5" align="center">
										Galera Manager Login
									</Typography>
									<Grid container direction="column" spacing={1}>
										<TextField
											fullWidth={true}
											margin="dense"
											autoComplete="username"
											label="Username"
											name="username"
											value={credentials.name}
											onChange={(e) =>
												this.setState({
													credentials: {
														...this.state.credentials,
														name: e.target.value
													}
												})
											}
											inputProps={{
												"data-testid": "username"
											}}
										/>
										<TextField
											fullWidth={true}
											margin="dense"
											autoComplete="current-password"
											label="Password"
											type="password"
											name="password"
											value={credentials.password}
											onChange={(e) =>
												this.setState({
													credentials: {
														...this.state.credentials,
														password: e.target.value
													}
												})
											}
											inputProps={{
												"data-testid": "password"
											}}
										/>
										{!isLoggingIn && loginErrorMsg && (
											<Typography color="error">{loginErrorMsg}</Typography>
										)}
									</Grid>
								</CardContent>
								<CardActions>
									<Button
										type="submit"
										disabled={isLoggingIn}
										fullWidth={true}
										color={"primary"}
										variant={"contained"}
									>
										{isLoggingIn && (
											<CircularProgress
												className={classes.loginLoader}
												size={20}
											/>
										)}
										Login
									</Button>
								</CardActions>
							</form>
						</Card>
					</Grid>
				</Grid>
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => {
	return {
		isLoggingIn: state.auth.isLoggingIn,
		loginErrorMsg: state.auth.loginErrorMsg
	};
};

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		login: (credentials: Credentials) => {
			dispatch(login(credentials));
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	connect(mapGlobalStateToProps, mapGlobalDispatchToProps)(LoginComponent)
);
