import createStyles from '@mui/styles/createStyles';

export const styles = () =>
	createStyles({
		root: {
			position: "absolute",
			top: "14px",
			left: "40px",
			zIndex: 1,
			padding: "0 3px",
			borderRadius: "2px",
			backgroundColor: "rgba(255, 255, 255, 0.4)",
			fontSize: "smaller",
			height: "14px",
			overflow: "hidden",
			"&:hover": {
				height: "auto"
			}
		}
	});
