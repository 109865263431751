export enum JOB_STATUS {
	NONE = -2,
	MISSING = -1,
	NEW = "new",
	RUNNING = "running",
	SUCCESS = "success",
	FAILURE = "failure",
	ABORTED = "aborted"
}

export interface Job {
	id: number;
	description: string;
	parentId: number;
	executionInfo: {
		status: JOB_STATUS;
		details: string;
		error?: {
			msg: string;
			wrapped: {
				msg: string;
			};
		};
		payload: any;
	};
	createdAt: string;
	stoppedAt: string;
	meta: {
		cluster_id?: number;
		host_id?: number;
		node_id?: number;
	};
}

export interface SerializedJob {
	attributes: Job;
	type: "jobs";
	id?: string;
}
