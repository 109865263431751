import React from "react";
import clsx from "clsx";
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import TableCell from "@mui/material/TableCell";
import {
	AutoSizer,
	Column,
	Table,
	TableCellRenderer,
	TableHeaderProps
} from "react-virtualized";
import { styles } from "./styles";

interface ColumnData {
	dataKey: string;
	label: string;
	numeric?: boolean;
	width: number;
	flexGrow?: number;
}

interface Row {
	index: number;
}

interface Props extends WithStyles<typeof styles> {
	columns: ColumnData[];
	headerHeight: number;
	onRowClick?: () => void;
	rowCount: number;
	rowGetter: (row: Row) => any;
	rowHeight: number;
}

class HostLogTable extends React.PureComponent<Props> {
	static defaultProps = {
		headerHeight: 30,
		rowHeight: 16
	};

	getRowClassName = ({ index }: Row) => {
		const { classes, onRowClick } = this.props;

		return clsx(classes.tableRow, classes.flexContainer, {
			[classes.tableRowHover]: index !== -1 && onRowClick != null
		});
	};

	cellRenderer: TableCellRenderer = ({ cellData, columnIndex }) => {
		const { classes, rowHeight, onRowClick } = this.props;
		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, {
					[classes.noClick]: onRowClick == null
				})}
				variant="body"
				style={{ height: rowHeight }}
			>
				{cellData}
			</TableCell>
		);
	};

	headerRenderer = ({
		label,
		columnIndex
	}: TableHeaderProps & { columnIndex: number }) => {
		const { headerHeight, columns, classes } = this.props;

		return (
			<TableCell
				component="div"
				className={clsx(
					classes.tableCell,
					classes.flexContainer,
					classes.noClick
				)}
				variant="head"
				style={{ height: headerHeight }}
				align={columns[columnIndex].numeric || false ? "right" : "left"}
			>
				<span>{label}</span>
			</TableCell>
		);
	};

	render() {
		const { classes, columns, ...tableProps } = this.props;

		return (
			<AutoSizer>
				{({ height, width }) => (
					<Table
						scrollToIndex={this.props.rowCount - 1}
						height={height}
						width={width}
						{...tableProps}
						rowClassName={this.getRowClassName}
					>
						{columns.map(({ dataKey, ...other }, index) => {
							return (
								<Column
									key={dataKey}
									headerRenderer={(headerProps) =>
										this.headerRenderer({
											...headerProps,
											columnIndex: index
										})
									}
									className={classes.flexContainer}
									cellRenderer={this.cellRenderer}
									dataKey={dataKey}
									{...other}
								/>
							);
						})}
					</Table>
				)}
			</AutoSizer>
		);
	}
}

export default withStyles(styles)(HostLogTable);
