import {
	JOB_MONITOR_ACTION,
	JobMonitorGetSuccessAction,
	JobMonitorStartAction,
	JobMonitorStopAction
} from "modules/jobs/monitor/types";
import { Job } from "modules/jobs/types";

export const jobMonitorStart = (jobId: number): JobMonitorStartAction => ({
	type: JOB_MONITOR_ACTION.START,
	payload: {
		jobId
	}
});

export const jobMonitorGetMonitoredSucceeded = (
	jobList: Job[]
): JobMonitorGetSuccessAction => ({
	type: JOB_MONITOR_ACTION.GET_MONITORED_SUCCESS,
	payload: {
		jobList
	}
});

export const jobMonitorGetRunningSucceeded = (
	jobList: Job[]
): JobMonitorGetSuccessAction => ({
	type: JOB_MONITOR_ACTION.GET_RUNNING_SUCCESS,
	payload: {
		jobList
	}
});

export const jobMonitorStop = (jobId: number): JobMonitorStopAction => ({
	type: JOB_MONITOR_ACTION.JOB_MONITOR_STOP,
	payload: {
		jobId
	}
});
