import { NODE_DB_ENGINE } from "components/management/node/types";

class NodeUtils {
	static getNodeDbEngineEnum = (value: string): NODE_DB_ENGINE => {
		switch (value) {
			case NODE_DB_ENGINE.MARIADB_10_3:
				return NODE_DB_ENGINE.MARIADB_10_3;
			case NODE_DB_ENGINE.MARIADB_10_4:
				return NODE_DB_ENGINE.MARIADB_10_4;
			case NODE_DB_ENGINE.MARIADB_10_5:
				return NODE_DB_ENGINE.MARIADB_10_5;
			case NODE_DB_ENGINE.MARIADB_10_6:
				return NODE_DB_ENGINE.MARIADB_10_6;
			case NODE_DB_ENGINE.MYSQL_5_7:
				return NODE_DB_ENGINE.MYSQL_5_7;
			case NODE_DB_ENGINE.MYSQL_8_0:
				return NODE_DB_ENGINE.MYSQL_8_0;
			default:
				console.warn(
					`Unrecognised node DB engine: ${value}. Returning default value instead.`
				);
				return NODE_DB_ENGINE.MARIADB_10_6;
		}
	};
}

export default NodeUtils;
