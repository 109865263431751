import { AppState } from "AppState";
import { createSelector } from "reselect";
import { MetricsStoreState } from "./storeTypes";
import { Cluster } from "../../components/management/cluster/types";

export const isMonitorEnabledSelector = (state: AppState): boolean =>
	state.metrics.isMonitorEnabled;

export const makeJoinedNodesSelector = () =>
	createSelector(
		(state: AppState) => state.metrics,
		(state: AppState) => state.clusterList,
		(state: AppState, clusterId: number = -1) => clusterId,
		(
			metricsStore: MetricsStoreState,
			clusterList: Cluster[],
			clusterID: number
		): number => {
			const cluster = clusterList.find(
				(cluster: Cluster) => cluster.id === clusterID
			);

			if (cluster) {
				return metricsStore.joinedNodes[cluster.name] || 0;
			} else {
				return 0;
			}
		}
	);
