class Config {
	private static instance: Config;

	static getInstance(): Config {
		if (!Config.instance) {
			Config.instance = new Config();
		}

		return Config.instance;
	}

	private _jtw_public_token?: string;
	private _influx_protocol?: "http" | "https";
	private _influx_host?: string;
	private _influx_port?: number;
	private _influx_username?: string;
	private _influx_password?: string;
	private _influx_db?: string;
	private _gui_version?: string;
	private _gmd_version?: string;
	private _app_version?: string;
	private _public_version?: string;
	private _support_matrix?: any;

	private constructor() {}

	set jtw_public_token(value: string) {
		this._jtw_public_token = value;
	}

	get jtw_public_token(): string {
		if (!this._jtw_public_token) throw Error("JWT_PUBLIC_TOKEN is missing.");

		return this._jtw_public_token;
	}

	get influx_protocol(): "http" | "https" {
		return this._influx_protocol || "http";
	}

	set influx_protocol(value: "http" | "https") {
		this._influx_protocol = value;
	}

	get influx_host(): string {
		return this._influx_host || "";
	}

	set influx_host(value: string) {
		this._influx_host = value;
	}

	get influx_port(): number {
		return this._influx_port || 8086;
	}

	set influx_port(value: number) {
		this._influx_port = value;
	}

	get influx_username(): string {
		return this._influx_username || "";
	}

	set influx_username(value: string) {
		this._influx_username = value;
	}

	get influx_password(): string {
		return this._influx_password || "";
	}

	set influx_password(value: string) {
		this._influx_password = value;
	}

	get influx_db(): string {
		return this._influx_db || "gmd";
	}

	set influx_db(value: string) {
		this._influx_db = value;
	}

	get gmd_version(): string {
		return this._gmd_version || "";
	}

	set gmd_version(value: string) {
		this._gmd_version = value;
	}

	get gui_version(): string {
		return this._gui_version || "";
	}

	set gui_version(value: string) {
		this._gui_version = value;
	}

	get app_version(): string {
		return this._app_version || "";
	}

	set app_version(value: string) {
		this._app_version = value;
	}

	get public_version(): string {
		return this._public_version || "";
	}

	set public_version(value: string) {
		this._public_version = value;
	}

	get support_matrix(): any {
		return this._support_matrix;
	}

	set support_matrix(value: any) {
		this._support_matrix = value;
	}
}

export default Config;
