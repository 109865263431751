import { AUTH_ACTION, AuthAction, AuthState } from "modules/auth/stateTypes";

export const defaultState: AuthState = {
	isAuthenticated: false,
	isLoggingIn: false,
	isLoggingOut: false
};

export function authReducer(
	state: AuthState = defaultState,
	action: AuthAction
): AuthState {
	switch (action.type) {
		case AUTH_ACTION.LOGIN:
			return {
				...state,
				isLoggingIn: true
			};

		case AUTH_ACTION.LOGIN_SUCCESS:
			return {
				...state,
				isLoggingIn: false,
				isAuthenticated: true,
				loginErrorMsg: undefined,
				userData: action.payload.userData
			};

		case AUTH_ACTION.LOGIN_FAILED:
			return {
				...state,
				isLoggingIn: false,
				loginErrorMsg: action.payload.errorMsg
			};

		case AUTH_ACTION.LOGOUT:
			return {
				...state,
				isLoggingOut: true
			};

		case AUTH_ACTION.LOGOUT_SUCCESS:
			return defaultState;

		case AUTH_ACTION.LOGOUT_FAILED:
			return {
				...state,
				isLoggingOut: false,
				logoutErrorMsg: action.payload.errorMsg
			};

		case AUTH_ACTION.FETCH_JWT_SUCCESS:
			return {
				...state,
				jwtAccessToken: action.payload.jwtTokenData,
				jwtAccessTokenErrorMsg: undefined
			};

		case AUTH_ACTION.FETCH_JWT_FAILED:
			return {
				...state,
				jwtAccessTokenErrorMsg: action.payload.errorMsg
			}

		default:
			return state;
	}
}
