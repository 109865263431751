import {
	ArrowBack,
	DeviceHub,
	ExitToApp,
	SupervisorAccount
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { WithStyles, WithTheme } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { AppState } from "AppState";
import gc_logo from "assets/images/gc_logo.png";
import logo from "assets/images/gc_logo_orange.png";
import ClusterCreateDialogComponent from "components/management/cluster/clusterCreateWizard/ClusterCreateDialogComponent";
import ClusterManagerComponent from "components/management/cluster/ClusterManagerComponent";
import HostManagerComponent from "components/management/host/HostManagerComponent";
import NodeDeploymentWizardComponent from "components/management/node/nodeDeploymentDialog/NodeDeploymentDialogComponent";
import NodeManagerComponent from "components/management/node/NodeManagerComponent";
import TreeViewComponent from "components/management/treeView/TreeViewComponent";
import { showSnackbar } from "components/sharedComponents/snackbar/actionCreators";
import { SnackbarActionPayload } from "components/sharedComponents/snackbar/types";
import SystemLogComponent from "components/systemLog/SystemLogComponent";
import UserCreateWizard from "components/users/userCreateWizard/UserCreateWizard";
import UserListComponent from "components/users/UserListComponent";
import UserManagerComponent from "components/users/UserManagerComponent";
import { History } from "history";
import { TextBoxOutline } from "mdi-material-ui";
import { logout } from "modules/auth/actions";
import SecurePreloader from "modules/preloader/SecurePreloaderComponent";
import { SecurePreloaderState } from "modules/preloader/types";
import React from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import {
	Redirect,
	Route,
	RouteComponentProps,
	Switch,
	withRouter
} from "react-router-dom";
import { styles } from "./styles";

interface LocalState {
	isDrawerOpen: boolean;
	showBackButton: boolean;
}

interface LocalProps {
	history?: History;
}

interface ReduxStateProps {
	securePreloader: SecurePreloaderState;
	isNodeDeploymentWizardOpen: boolean;
}

interface ReduxDispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
	logout: () => void;
}

type Props = LocalProps &
	ReduxDispatchProps &
	ReduxStateProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

class RootComponent extends React.Component<Props, LocalState> {
	history: History;

	constructor(props: Props) {
		super(props);
		this.history = props.history;

		this.state = {
			isDrawerOpen: false,
			showBackButton: false
		};
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.location !== prevProps.location) {
			this.updateShowBackButton();
		}
	}

	updateShowBackButton() {
		const { pathname } = this.props.location;

		// console.log("updateShowBackButton", pathname);

		this.setState(
			(state: LocalState): LocalState => ({
				...state,
				showBackButton: pathname.split("/").length > 2
			})
		);
	}

	handleDrawerToggle = () => {
		this.setState((state) => ({ isDrawerOpen: !state.isDrawerOpen }));
	};

	logOut = async () => {
		// todo: handle logging out loader and logout errors
		this.props.logout();

		// try {
		// 	await Auth.logout();
		// 	await Auth.clearJWTTokens();
		//
		// 	this.props.endUserSession();
		// 	this.history.push("/login");
		// } catch (e: any) {
		// 	console.error("Logout failed:", e);
		// 	GMDialogService.showInfo({ message: e.message, title: "Logout failed" });
		// }
	};

	render() {
		const {
			classes,
			securePreloader,
			isNodeDeploymentWizardOpen,
			history,
			theme
		} = this.props;
		const { showBackButton } = this.state;

		const drawer = (
			<div>
				{/*<Hidden smDown>*/}
				{/*	<div className={classes.toolbar} />*/}
				{/*</Hidden>*/}
				<div className="logo-container">
					<img
						className="galera-cluster-logo"
						src={gc_logo}
						alt="Galera Cluster Manager"
					/>
				</div>
				<Divider />
				<List>
					<ListItem
						button
						key={2}
						onClick={() => {
							history.push("/clusters");
							this.setState(
								(state: LocalState): LocalState => ({
									...state,
									isDrawerOpen: false
								})
							);
						}}
					>
						<ListItemIcon>
							<DeviceHub />
						</ListItemIcon>
						<ListItemText primary={"Management"} />
					</ListItem>
					<ListItem
						button
						key={3}
						onClick={() => {
							history.push("/users");
							this.setState(
								(state: LocalState): LocalState => ({
									...state,
									isDrawerOpen: false
								})
							);
						}}
					>
						<ListItemIcon>
							<SupervisorAccount />
						</ListItemIcon>
						<ListItemText primary={"Users"} />
					</ListItem>
					<ListItem
						button
						key={4}
						onClick={() => {
							history.push("/system-log");
							this.setState(
								(state: LocalState): LocalState => ({
									...state,
									isDrawerOpen: false
								})
							);
						}}
					>
						<ListItemIcon>
							<TextBoxOutline />
						</ListItemIcon>
						<ListItemText primary={"System log"} />
					</ListItem>
				</List>
				<Divider />
				<List>
					{/*<ListItem button key={1}>*/}
					{/*	<ListItemIcon>*/}
					{/*		<Settings />*/}
					{/*	</ListItemIcon>*/}
					{/*	<ListItemText primary={"Settings"} />*/}
					{/*</ListItem>*/}
					{/*<ListItem button key={2}>*/}
					{/*	<ListItemIcon>*/}
					{/*		<Info />*/}
					{/*	</ListItemIcon>*/}
					{/*	<ListItemText primary="About" />*/}
					{/*</ListItem>*/}
					<ListItem button id="LogOutButton" onClick={this.logOut} key={3}>
						<ListItemIcon>
							<ExitToApp />
						</ListItemIcon>
						<ListItemText primary={"Logout"} />
					</ListItem>
				</List>
			</div>
		);

		return (
			<>
				{!securePreloader.isPreloadDone ? (
					<Box height="100%">
						<SecurePreloader />
					</Box>
				) : (
					<Box className={classes.root} height="100%">
						<CssBaseline />
						<AppBar position="fixed" className={classes.appBar}>
							<Toolbar variant="dense">
								{theme.breakpoints.up("sm") ? (
									<IconButton
										id="MainMenuButton"
										color="inherit"
										aria-label="Open drawer"
										onClick={this.handleDrawerToggle}
										className={classes.menuButton}
										size="large"
									>
										<MenuIcon />
									</IconButton>
								) : (
									<>
										{showBackButton ? (
											<IconButton
												id="GoBackButton"
												color="inherit"
												aria-label="Go back"
												onClick={() => {
													this.props.history.goBack();
												}}
												className={classes.menuButton}
												size="large"
											>
												<ArrowBack />
											</IconButton>
										) : (
											<IconButton
												id="MainMenuButton"
												color="inherit"
												aria-label="Open drawer"
												onClick={this.handleDrawerToggle}
												className={classes.menuButton}
												size="large"
											>
												<MenuIcon />
											</IconButton>
										)}

										<img
											className="logo"
											src={logo}
											height="30px"
											alt="Galera Cluster Logo"
										/>
									</>
								)}

								<Typography variant="h6" color="inherit" noWrap>
									Galera Manager
								</Typography>
							</Toolbar>
						</AppBar>
						<nav className={classes.drawer}>
							{/*Mobile*/}
							{/*<HiddenJs smUp>*/}
							<Drawer
								variant="temporary"
								open={this.state.isDrawerOpen}
								onClose={this.handleDrawerToggle}
								classes={{
									paper: classes.drawerPaper
								}}
							>
								{drawer}
							</Drawer>
							{/*</HiddenJs>*/}
							{/*Desktop*/}
							{/*<HiddenJs xsDown>*/}
							{/*	<Drawer*/}
							{/*		classes={{*/}
							{/*			paper: classes.drawerPaper*/}
							{/*		}}*/}
							{/*		variant="permanent"*/}
							{/*		open*/}
							{/*	>*/}
							{/*		{drawer}*/}
							{/*	</Drawer>*/}
							{/*</HiddenJs>*/}
						</nav>
						<Grid
							container
							direction="column"
							component="main"
							className={classes.content}
						>
							{theme.breakpoints.up("sm") ? (
								<Grid container direction="column">
									<Grid item style={{ height: 58 }} />

									<Grid
										item
										id="container"
										container
										direction="row"
										xs
										spacing={2}
									>
										<Route exact path="/">
											<Redirect to="/clusters" />
										</Route>
										{/*Dialogs*/}
										<Route
											path={["/clusters", "/nodes", "/hosts"]}
											component={ClusterCreateDialogComponent}
										/>

										<Route path="/users" component={UserCreateWizard} />

										{/*Display tree view for cluster management*/}
										<Grid item>
											<Route path="/users" component={UserListComponent} />
											<Route
												path={["/clusters", "/nodes", "/hosts"]}
												component={TreeViewComponent}
											/>
										</Grid>

										{/*Details pages*/}
										<Grid item xs>
											<Switch>
												<Route
													path="/clusters/:clusterID"
													exact
													component={ClusterManagerComponent}
												/>
												<Route
													path="/clusters/:clusterID/nodes/:nodeID"
													exact
													component={NodeManagerComponent}
												/>

												<Route
													path="/clusters/:clusterID/hosts/:hostID"
													exact
													component={HostManagerComponent}
												/>

												<Route
													path="/users/:name" // todo: change to /users/:userID to be consistent with cluster management routing
													exact
													component={UserManagerComponent}
												/>

												<Route
													path="/system-log"
													exact
													component={SystemLogComponent}
												/>
											</Switch>
										</Grid>
									</Grid>
								</Grid>
							) : (
								<>
									<Grid item />

									<Route path="/" exact component={TreeViewComponent} />
									<Route path="/" component={ClusterCreateDialogComponent} />
									{/*<Route path="/" component={NodeDeploymentWizardComponent} />*/}
									<Route
										path="/clusters/:name"
										exact
										component={ClusterManagerComponent}
									/>
									<Route
										path="/nodes/:name"
										exact
										component={NodeManagerComponent}
									/>
									<Route
										path="/hosts/:name"
										exact
										component={HostManagerComponent}
									/>
								</>
							)}
						</Grid>
						{isNodeDeploymentWizardOpen && <NodeDeploymentWizardComponent />}
					</Box>
				)}
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({
	isNodeDeploymentWizardOpen: state.nodeCreateWizard.isOpen,
	securePreloader: state.preloader.securePreloader
});

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		showSnackbar: (snackbar: SnackbarActionPayload) => {
			dispatch(showSnackbar(snackbar));
		},
		logout: () => {
			dispatch(logout());
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(mapGlobalStateToProps, mapGlobalDispatchToProps)(RootComponent)
	)
);
