import {
	CLUSTER_CREATE_WIZARD_ACTION,
	ClusterCreateWizardHideAction,
	ClusterCreateWizardShowAction,
} from "components/management/cluster/clusterCreateWizard/types";

export const clusterCreateWizardShow = (): ClusterCreateWizardShowAction => ({
	type: CLUSTER_CREATE_WIZARD_ACTION.SHOW
});

export const clusterCreateWizardHide = (): ClusterCreateWizardHideAction => ({
	type: CLUSTER_CREATE_WIZARD_ACTION.HIDE
});
